(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/libs/date-fns/assets/javascripts/date-fns-swedish.js') >= 0) return;  svs.modules.push('/components/libs/date-fns/assets/javascripts/date-fns-swedish.js');
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
  typeof define === 'function' && define.amd ? define(factory) :
  (global = typeof globalThis !== 'undefined' ? globalThis : global || self, (global.dateFns = global.dateFns || {}, global.dateFns.locales = global.dateFns.locales || {}, global.dateFns.locales.sv = factory()));
}(this, (function () { 'use strict';

  var formatDistanceLocale = {
    lessThanXSeconds: {
      singular: 'mindre än en sekund',
      plural: 'mindre än {{count}} sekunder'
    },
    xSeconds: {
      singular: 'en sekund',
      plural: '{{count}} sekunder'
    },
    halfAMinute: 'en halv minut',
    lessThanXMinutes: {
      singular: 'mindre än en minut',
      plural: 'mindre än {{count}} minuter'
    },
    xMinutes: {
      singular: 'en minut',
      plural: '{{count}} minuter'
    },
    aboutXHours: {
      singular: 'ungefär en timme',
      plural: 'ungefär {{count}} timmar'
    },
    xHours: {
      singular: 'en timme',
      plural: '{{count}} timmar'
    },
    xDays: {
      singular: 'en dag',
      plural: '{{count}} dagar'
    },
    aboutXWeeks: {
      singular: 'ungefär en vecka',
      plural: 'ungefär {{count}} vecka'
    },
    xWeeks: {
      singular: 'en vecka',
      plural: '{{count}} vecka'
    },
    aboutXMonths: {
      singular: 'ungefär en månad',
      plural: 'ungefär {{count}} månader'
    },
    xMonths: {
      singular: 'en månad',
      plural: '{{count}} månader'
    },
    aboutXYears: {
      singular: 'ungefär ett år',
      plural: 'ungefär {{count}} år'
    },
    xYears: {
      singular: 'ett år',
      plural: '{{count}} år'
    },
    overXYears: {
      singular: 'över ett år',
      plural: 'över {{count}} år'
    },
    almostXYears: {
      singular: 'nästan ett år',
      plural: 'nästan {{count}} år'
    }
  };
  var wordMapping = ['noll', 'en', 'två', 'tre', 'fyra', 'fem', 'sex', 'sju', 'åtta', 'nio', 'tio', 'elva', 'tolv'];
  function formatDistance(token, count, options) {
    options = options || {
      onlyNumeric: false
    };
    var translation = formatDistanceLocale[token];
    var result;

    if (typeof translation === 'string') {
      result = translation;
    } else if (count === 0 || count > 1) {
      if (options.onlyNumeric) {
        result = translation.plural.replace('{{count}}', count);
      } else {
        result = translation.plural.replace('{{count}}', count < 13 ? wordMapping[count] : count);
      }
    } else {
      result = translation.singular;
    }

    if (options.addSuffix) {
      if (options.comparison > 0) {
        return 'om ' + result;
      } else {
        return result + ' sedan';
      }
    }

    return result;
  }

  function buildFormatLongFn(args) {
    return function (dirtyOptions) {
      var options = dirtyOptions || {};
      var width = options.width ? String(options.width) : args.defaultWidth;
      var format = args.formats[width] || args.formats[args.defaultWidth];
      return format;
    };
  }

  var dateFormats = {
    full: 'EEEE d MMMM y',
    long: 'd MMMM y',
    medium: 'd MMM y',
    short: 'y-MM-dd'
  };
  var timeFormats = {
    full: "'kl'. HH:mm:ss zzzz",
    long: 'HH:mm:ss z',
    medium: 'HH:mm:ss',
    short: 'HH:mm'
  };
  var dateTimeFormats = {
    full: "{{date}} 'kl.' {{time}}",
    long: "{{date}} 'kl.' {{time}}",
    medium: '{{date}} {{time}}',
    short: '{{date}} {{time}}'
  };
  var formatLong = {
    date: buildFormatLongFn({
      formats: dateFormats,
      defaultWidth: 'full'
    }),
    time: buildFormatLongFn({
      formats: timeFormats,
      defaultWidth: 'full'
    }),
    dateTime: buildFormatLongFn({
      formats: dateTimeFormats,
      defaultWidth: 'full'
    })
  };

  var formatRelativeLocale = {
    lastWeek: "'i' EEEE's kl.' p",
    yesterday: "'igår kl.' p",
    today: "'idag kl.' p",
    tomorrow: "'imorgon kl.' p",
    nextWeek: "EEEE 'kl.' p",
    other: 'P'
  };
  function formatRelative(token, _date, _baseDate, _options) {
    return formatRelativeLocale[token];
  }

  function buildLocalizeFn(args) {
    return function (dirtyIndex, dirtyOptions) {
      var options = dirtyOptions || {};
      var context = options.context ? String(options.context) : 'standalone';
      var valuesArray;

      if (context === 'formatting' && args.formattingValues) {
        var defaultWidth = args.defaultFormattingWidth || args.defaultWidth;
        var width = options.width ? String(options.width) : defaultWidth;
        valuesArray = args.formattingValues[width] || args.formattingValues[defaultWidth];
      } else {
        var _defaultWidth = args.defaultWidth;

        var _width = options.width ? String(options.width) : args.defaultWidth;

        valuesArray = args.values[_width] || args.values[_defaultWidth];
      }

      var index = args.argumentCallback ? args.argumentCallback(dirtyIndex) : dirtyIndex;
      return valuesArray[index];
    };
  }

  var eraValues = {
    narrow: ['f.Kr.', 'e.Kr.'],
    abbreviated: ['f.Kr.', 'e.Kr.'],
    wide: ['före Kristus', 'efter Kristus']
  };
  var quarterValues = {
    narrow: ['1', '2', '3', '4'],
    abbreviated: ['Q1', 'Q2', 'Q3', 'Q4'],
    wide: ['1:a kvartalet', '2:a kvartalet', '3:e kvartalet', '4:e kvartalet']
  };
  var monthValues = {
    narrow: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
    abbreviated: ['jan.', 'feb.', 'mars', 'apr.', 'maj', 'juni', 'juli', 'aug.', 'sep.', 'okt.', 'nov.', 'dec.'],
    wide: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december']
  };
  var dayValues = {
    narrow: ['S', 'M', 'T', 'O', 'T', 'F', 'L'],
    short: ['sö', 'må', 'ti', 'on', 'to', 'fr', 'lö'],
    abbreviated: ['sön', 'mån', 'tis', 'ons', 'tor', 'fre', 'lör'],
    wide: ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'] 

  };
  var dayPeriodValues = {
    narrow: {
      am: 'fm',
      pm: 'em',
      midnight: 'midnatt',
      noon: 'middag',
      morning: 'morg.',
      afternoon: 'efterm.',
      evening: 'kväll',
      night: 'natt'
    },
    abbreviated: {
      am: 'f.m.',
      pm: 'e.m.',
      midnight: 'midnatt',
      noon: 'middag',
      morning: 'morgon',
      afternoon: 'efterm.',
      evening: 'kväll',
      night: 'natt'
    },
    wide: {
      am: 'förmiddag',
      pm: 'eftermiddag',
      midnight: 'midnatt',
      noon: 'middag',
      morning: 'morgon',
      afternoon: 'eftermiddag',
      evening: 'kväll',
      night: 'natt'
    }
  };
  var formattingDayPeriodValues = {
    narrow: {
      am: 'fm',
      pm: 'em',
      midnight: 'midnatt',
      noon: 'middag',
      morning: 'på morg.',
      afternoon: 'på efterm.',
      evening: 'på kvällen',
      night: 'på natten'
    },
    abbreviated: {
      am: 'fm',
      pm: 'em',
      midnight: 'midnatt',
      noon: 'middag',
      morning: 'på morg.',
      afternoon: 'på efterm.',
      evening: 'på kvällen',
      night: 'på natten'
    },
    wide: {
      am: 'fm',
      pm: 'em',
      midnight: 'midnatt',
      noon: 'middag',
      morning: 'på morgonen',
      afternoon: 'på eftermiddagen',
      evening: 'på kvällen',
      night: 'på natten'
    }
  };

  function ordinalNumber(dirtyNumber) {
    var number = Number(dirtyNumber);
    var rem100 = number % 100;

    if (rem100 > 20 || rem100 < 10) {
      switch (rem100 % 10) {
        case 1:
        case 2:
          return number + ':a';
      }
    }

    return number + ':e';
  }

  var localize = {
    ordinalNumber: ordinalNumber,
    era: buildLocalizeFn({
      values: eraValues,
      defaultWidth: 'wide'
    }),
    quarter: buildLocalizeFn({
      values: quarterValues,
      defaultWidth: 'wide',
      argumentCallback: function (quarter) {
        return Number(quarter) - 1;
      }
    }),
    month: buildLocalizeFn({
      values: monthValues,
      defaultWidth: 'wide'
    }),
    day: buildLocalizeFn({
      values: dayValues,
      defaultWidth: 'wide'
    }),
    dayPeriod: buildLocalizeFn({
      values: dayPeriodValues,
      defaultWidth: 'wide',
      formattingValues: formattingDayPeriodValues,
      defaultFormattingWidth: 'wide'
    })
  };

  function buildMatchPatternFn(args) {
    return function (dirtyString, dirtyOptions) {
      var string = String(dirtyString);
      var options = dirtyOptions || {};
      var matchResult = string.match(args.matchPattern);

      if (!matchResult) {
        return null;
      }

      var matchedString = matchResult[0];
      var parseResult = string.match(args.parsePattern);

      if (!parseResult) {
        return null;
      }

      var value = args.valueCallback ? args.valueCallback(parseResult[0]) : parseResult[0];
      value = options.valueCallback ? options.valueCallback(value) : value;
      return {
        value: value,
        rest: string.slice(matchedString.length)
      };
    };
  }

  function buildMatchFn(args) {
    return function (dirtyString, dirtyOptions) {
      var string = String(dirtyString);
      var options = dirtyOptions || {};
      var width = options.width;
      var matchPattern = width && args.matchPatterns[width] || args.matchPatterns[args.defaultMatchWidth];
      var matchResult = string.match(matchPattern);

      if (!matchResult) {
        return null;
      }

      var matchedString = matchResult[0];
      var parsePatterns = width && args.parsePatterns[width] || args.parsePatterns[args.defaultParseWidth];
      var value;

      if (Object.prototype.toString.call(parsePatterns) === '[object Array]') {
        value = findIndex(parsePatterns, function (pattern) {
          return pattern.test(matchedString);
        });
      } else {
        value = findKey(parsePatterns, function (pattern) {
          return pattern.test(matchedString);
        });
      }

      value = args.valueCallback ? args.valueCallback(value) : value;
      value = options.valueCallback ? options.valueCallback(value) : value;
      return {
        value: value,
        rest: string.slice(matchedString.length)
      };
    };
  }

  function findKey(object, predicate) {
    for (var key in object) {
      if (object.hasOwnProperty(key) && predicate(object[key])) {
        return key;
      }
    }
  }

  function findIndex(array, predicate) {
    for (var key = 0; key < array.length; key++) {
      if (predicate(array[key])) {
        return key;
      }
    }
  }

  var matchOrdinalNumberPattern = /^(\d+)(:a|:e)?/i;
  var parseOrdinalNumberPattern = /\d+/i;
  var matchEraPatterns = {
    narrow: /^(f\.? ?Kr\.?|f\.? ?v\.? ?t\.?|e\.? ?Kr\.?|v\.? ?t\.?)/i,
    abbreviated: /^(f\.? ?Kr\.?|f\.? ?v\.? ?t\.?|e\.? ?Kr\.?|v\.? ?t\.?)/i,
    wide: /^(före Kristus|före vår tid|efter Kristus|vår tid)/i
  };
  var parseEraPatterns = {
    any: [/^f/i, /^[ev]/i]
  };
  var matchQuarterPatterns = {
    narrow: /^[1234]/i,
    abbreviated: /^q[1234]/i,
    wide: /^[1234](:a|:e)? kvartalet/i
  };
  var parseQuarterPatterns = {
    any: [/1/i, /2/i, /3/i, /4/i]
  };
  var matchMonthPatterns = {
    narrow: /^[jfmasond]/i,
    abbreviated: /^(jan|feb|mar|apr|maj|jun|jul|aug|sep|okt|nov|dec)\.?/i,
    wide: /^(januari|februari|mars|april|maj|juni|juli|augusti|september|oktober|november|december)/i
  };
  var parseMonthPatterns = {
    narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
    any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^maj/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
  };
  var matchDayPatterns = {
    narrow: /^[smtofl]/i,
    short: /^(sö|må|ti|on|to|fr|lö)/i,
    abbreviated: /^(sön|mån|tis|ons|tor|fre|lör)/i,
    wide: /^(söndag|måndag|tisdag|onsdag|torsdag|fredag|lördag)/i
  };
  var parseDayPatterns = {
    any: [/^s/i, /^m/i, /^ti/i, /^o/i, /^to/i, /^f/i, /^l/i]
  };
  var matchDayPeriodPatterns = {
    any: /^([fe]\.?\s?m\.?|midn(att)?|midd(ag)?|(på) (morgonen|eftermiddagen|kvällen|natten))/i
  };
  var parseDayPeriodPatterns = {
    any: {
      am: /^f/i,
      pm: /^e/i,
      midnight: /^midn/i,
      noon: /^midd/i,
      morning: /morgon/i,
      afternoon: /eftermiddag/i,
      evening: /kväll/i,
      night: /natt/i
    }
  };
  var match = {
    ordinalNumber: buildMatchPatternFn({
      matchPattern: matchOrdinalNumberPattern,
      parsePattern: parseOrdinalNumberPattern,
      valueCallback: function (value) {
        return parseInt(value, 10);
      }
    }),
    era: buildMatchFn({
      matchPatterns: matchEraPatterns,
      defaultMatchWidth: 'wide',
      parsePatterns: parseEraPatterns,
      defaultParseWidth: 'any'
    }),
    quarter: buildMatchFn({
      matchPatterns: matchQuarterPatterns,
      defaultMatchWidth: 'wide',
      parsePatterns: parseQuarterPatterns,
      defaultParseWidth: 'any',
      valueCallback: function (index) {
        return index + 1;
      }
    }),
    month: buildMatchFn({
      matchPatterns: matchMonthPatterns,
      defaultMatchWidth: 'wide',
      parsePatterns: parseMonthPatterns,
      defaultParseWidth: 'any'
    }),
    day: buildMatchFn({
      matchPatterns: matchDayPatterns,
      defaultMatchWidth: 'wide',
      parsePatterns: parseDayPatterns,
      defaultParseWidth: 'any'
    }),
    dayPeriod: buildMatchFn({
      matchPatterns: matchDayPeriodPatterns,
      defaultMatchWidth: 'any',
      parsePatterns: parseDayPeriodPatterns,
      defaultParseWidth: 'any'
    })
  };


  var locale = {
    code: 'sv',
    formatDistance: formatDistance,
    formatLong: formatLong,
    formatRelative: formatRelative,
    localize: localize,
    match: match,
    options: {
      weekStartsOn: 1
      ,
      firstWeekContainsDate: 4
    }
  };

  return locale;

})));


 })(window);