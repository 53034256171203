(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/libs/date-fns/assets/javascripts/date-fns.js') >= 0) return;  svs.modules.push('/components/libs/date-fns/assets/javascripts/date-fns.js');
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
  typeof define === 'function' && define.amd ? define(['exports'], factory) :
  (global = typeof globalThis !== 'undefined' ? globalThis : global || self, factory(global.dateFns = {}));
}(this, (function (exports) { 'use strict';

  function toInteger(dirtyNumber) {
    if (dirtyNumber === null || dirtyNumber === true || dirtyNumber === false) {
      return NaN;
    }

    var number = Number(dirtyNumber);

    if (isNaN(number)) {
      return number;
    }

    return number < 0 ? Math.ceil(number) : Math.floor(number);
  }

  function requiredArgs(required, args) {
    if (args.length < required) {
      throw new TypeError(required + ' argument' + (required > 1 ? 's' : '') + ' required, but only ' + args.length + ' present');
    }
  }


  function toDate(argument) {
    requiredArgs(1, arguments);
    var argStr = Object.prototype.toString.call(argument); 

    if (argument instanceof Date || typeof argument === 'object' && argStr === '[object Date]') {
      return new Date(argument.getTime());
    } else if (typeof argument === 'number' || argStr === '[object Number]') {
      return new Date(argument);
    } else {
      if ((typeof argument === 'string' || argStr === '[object String]') && typeof console !== 'undefined') {
        console.warn("Starting with v2.0.0-beta.1 date-fns doesn't accept strings as date arguments. Please use `parseISO` to parse strings. See: https://git.io/fjule"); 

        console.warn(new Error().stack);
      }

      return new Date(NaN);
    }
  }


  function addDays(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var amount = toInteger(dirtyAmount);

    if (isNaN(amount)) {
      return new Date(NaN);
    }

    if (!amount) {
      return date;
    }

    date.setDate(date.getDate() + amount);
    return date;
  }


  function addMonths(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var amount = toInteger(dirtyAmount);

    if (isNaN(amount)) {
      return new Date(NaN);
    }

    if (!amount) {
      return date;
    }

    var dayOfMonth = date.getDate(); 

    var endOfDesiredMonth = new Date(date.getTime());
    endOfDesiredMonth.setMonth(date.getMonth() + amount + 1, 0);
    var daysInMonth = endOfDesiredMonth.getDate();

    if (dayOfMonth >= daysInMonth) {
      return endOfDesiredMonth;
    } else {
      date.setFullYear(endOfDesiredMonth.getFullYear(), endOfDesiredMonth.getMonth(), dayOfMonth);
      return date;
    }
  }


  function add(dirtyDate, duration) {
    requiredArgs(2, arguments);
    if (!duration || typeof duration !== 'object') return new Date(NaN);
    var years = 'years' in duration ? toInteger(duration.years) : 0;
    var months = 'months' in duration ? toInteger(duration.months) : 0;
    var weeks = 'weeks' in duration ? toInteger(duration.weeks) : 0;
    var days = 'days' in duration ? toInteger(duration.days) : 0;
    var hours = 'hours' in duration ? toInteger(duration.hours) : 0;
    var minutes = 'minutes' in duration ? toInteger(duration.minutes) : 0;
    var seconds = 'seconds' in duration ? toInteger(duration.seconds) : 0; 

    var date = toDate(dirtyDate);
    var dateWithMonths = months || years ? addMonths(date, months + years * 12) : date; 

    var dateWithDays = days || weeks ? addDays(dateWithMonths, days + weeks * 7) : dateWithMonths; 

    var minutesToAdd = minutes + hours * 60;
    var secondsToAdd = seconds + minutesToAdd * 60;
    var msToAdd = secondsToAdd * 1000;
    var finalDate = new Date(dateWithDays.getTime() + msToAdd);
    return finalDate;
  }


  function isWeekend(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var day = date.getDay();
    return day === 0 || day === 6;
  }


  function isSunday(dirtyDate) {
    requiredArgs(1, arguments);
    return toDate(dirtyDate).getDay() === 0;
  }


  function isSaturday(dirtyDate) {
    requiredArgs(1, arguments);
    return toDate(dirtyDate).getDay() === 6;
  }


  function addBusinessDays(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var startedOnWeekend = isWeekend(date);
    var amount = toInteger(dirtyAmount);
    if (isNaN(amount)) return new Date(NaN);
    var hours = date.getHours();
    var sign = amount < 0 ? -1 : 1;
    var fullWeeks = toInteger(amount / 5);
    date.setDate(date.getDate() + fullWeeks * 7); 

    var restDays = Math.abs(amount % 5); 

    while (restDays > 0) {
      date.setDate(date.getDate() + sign);
      if (!isWeekend(date)) restDays -= 1;
    } 


    if (startedOnWeekend && isWeekend(date) && amount !== 0) {
      if (isSaturday(date)) date.setDate(date.getDate() + (sign < 0 ? 2 : -1));
      if (isSunday(date)) date.setDate(date.getDate() + (sign < 0 ? 1 : -2));
    } 


    date.setHours(hours);
    return date;
  }


  function addMilliseconds(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var timestamp = toDate(dirtyDate).getTime();
    var amount = toInteger(dirtyAmount);
    return new Date(timestamp + amount);
  }

  var MILLISECONDS_IN_HOUR$3 = 3600000;

  function addHours(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addMilliseconds(dirtyDate, amount * MILLISECONDS_IN_HOUR$3);
  }


  function startOfWeek(dirtyDate, dirtyOptions) {
    requiredArgs(1, arguments);
    var options = dirtyOptions || {};
    var locale = options.locale;
    var localeWeekStartsOn = locale && locale.options && locale.options.weekStartsOn;
    var defaultWeekStartsOn = localeWeekStartsOn == null ? 0 : toInteger(localeWeekStartsOn);
    var weekStartsOn = options.weekStartsOn == null ? defaultWeekStartsOn : toInteger(options.weekStartsOn); 

    if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
      throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
    }

    var date = toDate(dirtyDate);
    var day = date.getDay();
    var diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
    date.setDate(date.getDate() - diff);
    date.setHours(0, 0, 0, 0);
    return date;
  }


  function startOfISOWeek(dirtyDate) {
    requiredArgs(1, arguments);
    return startOfWeek(dirtyDate, {
      weekStartsOn: 1
    });
  }


  function getISOWeekYear(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var year = date.getFullYear();
    var fourthOfJanuaryOfNextYear = new Date(0);
    fourthOfJanuaryOfNextYear.setFullYear(year + 1, 0, 4);
    fourthOfJanuaryOfNextYear.setHours(0, 0, 0, 0);
    var startOfNextYear = startOfISOWeek(fourthOfJanuaryOfNextYear);
    var fourthOfJanuaryOfThisYear = new Date(0);
    fourthOfJanuaryOfThisYear.setFullYear(year, 0, 4);
    fourthOfJanuaryOfThisYear.setHours(0, 0, 0, 0);
    var startOfThisYear = startOfISOWeek(fourthOfJanuaryOfThisYear);

    if (date.getTime() >= startOfNextYear.getTime()) {
      return year + 1;
    } else if (date.getTime() >= startOfThisYear.getTime()) {
      return year;
    } else {
      return year - 1;
    }
  }


  function startOfISOWeekYear(dirtyDate) {
    requiredArgs(1, arguments);
    var year = getISOWeekYear(dirtyDate);
    var fourthOfJanuary = new Date(0);
    fourthOfJanuary.setFullYear(year, 0, 4);
    fourthOfJanuary.setHours(0, 0, 0, 0);
    var date = startOfISOWeek(fourthOfJanuary);
    return date;
  }

  var MILLISECONDS_IN_MINUTE$4 = 60000;

  function getDateMillisecondsPart(date) {
    return date.getTime() % MILLISECONDS_IN_MINUTE$4;
  }


  function getTimezoneOffsetInMilliseconds(dirtyDate) {
    var date = new Date(dirtyDate.getTime());
    var baseTimezoneOffset = Math.ceil(date.getTimezoneOffset());
    date.setSeconds(0, 0);
    var hasNegativeUTCOffset = baseTimezoneOffset > 0;
    var millisecondsPartOfTimezoneOffset = hasNegativeUTCOffset ? (MILLISECONDS_IN_MINUTE$4 + getDateMillisecondsPart(date)) % MILLISECONDS_IN_MINUTE$4 : getDateMillisecondsPart(date);
    return baseTimezoneOffset * MILLISECONDS_IN_MINUTE$4 + millisecondsPartOfTimezoneOffset;
  }


  function startOfDay(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  var MILLISECONDS_IN_DAY$2 = 86400000;

  function differenceInCalendarDays(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var startOfDayLeft = startOfDay(dirtyDateLeft);
    var startOfDayRight = startOfDay(dirtyDateRight);
    var timestampLeft = startOfDayLeft.getTime() - getTimezoneOffsetInMilliseconds(startOfDayLeft);
    var timestampRight = startOfDayRight.getTime() - getTimezoneOffsetInMilliseconds(startOfDayRight); 

    return Math.round((timestampLeft - timestampRight) / MILLISECONDS_IN_DAY$2);
  }


  function setISOWeekYear(dirtyDate, dirtyISOWeekYear) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var isoWeekYear = toInteger(dirtyISOWeekYear);
    var diff = differenceInCalendarDays(date, startOfISOWeekYear(date));
    var fourthOfJanuary = new Date(0);
    fourthOfJanuary.setFullYear(isoWeekYear, 0, 4);
    fourthOfJanuary.setHours(0, 0, 0, 0);
    date = startOfISOWeekYear(fourthOfJanuary);
    date.setDate(date.getDate() + diff);
    return date;
  }


  function addISOWeekYears(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return setISOWeekYear(dirtyDate, getISOWeekYear(dirtyDate) + amount);
  }

  var MILLISECONDS_IN_MINUTE$3 = 60000;

  function addMinutes(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addMilliseconds(dirtyDate, amount * MILLISECONDS_IN_MINUTE$3);
  }


  function addQuarters(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    var months = amount * 3;
    return addMonths(dirtyDate, months);
  }


  function addSeconds(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addMilliseconds(dirtyDate, amount * 1000);
  }


  function addWeeks(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    var days = amount * 7;
    return addDays(dirtyDate, days);
  }


  function addYears(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addMonths(dirtyDate, amount * 12);
  }


  function areIntervalsOverlapping(dirtyIntervalLeft, dirtyIntervalRight) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    requiredArgs(2, arguments);
    var intervalLeft = dirtyIntervalLeft || {};
    var intervalRight = dirtyIntervalRight || {};
    var leftStartTime = toDate(intervalLeft.start).getTime();
    var leftEndTime = toDate(intervalLeft.end).getTime();
    var rightStartTime = toDate(intervalRight.start).getTime();
    var rightEndTime = toDate(intervalRight.end).getTime(); 

    if (!(leftStartTime <= leftEndTime && rightStartTime <= rightEndTime)) {
      throw new RangeError('Invalid interval');
    }

    if (options.inclusive) {
      return leftStartTime <= rightEndTime && rightStartTime <= leftEndTime;
    }

    return leftStartTime < rightEndTime && rightStartTime < leftEndTime;
  }


  function closestIndexTo(dirtyDateToCompare, dirtyDatesArray) {
    requiredArgs(2, arguments);
    var dateToCompare = toDate(dirtyDateToCompare);

    if (isNaN(dateToCompare)) {
      return NaN;
    }

    var timeToCompare = dateToCompare.getTime();
    var datesArray; 

    if (dirtyDatesArray == null) {
      datesArray = []; 
    } else if (typeof dirtyDatesArray.forEach === 'function') {
      datesArray = dirtyDatesArray; 
    } else {
      datesArray = Array.prototype.slice.call(dirtyDatesArray);
    }

    var result;
    var minDistance;
    datesArray.forEach(function (dirtyDate, index) {
      var currentDate = toDate(dirtyDate);

      if (isNaN(currentDate)) {
        result = NaN;
        minDistance = NaN;
        return;
      }

      var distance = Math.abs(timeToCompare - currentDate.getTime());

      if (result == null || distance < minDistance) {
        result = index;
        minDistance = distance;
      }
    });
    return result;
  }


  function closestTo(dirtyDateToCompare, dirtyDatesArray) {
    requiredArgs(2, arguments);
    var dateToCompare = toDate(dirtyDateToCompare);

    if (isNaN(dateToCompare)) {
      return new Date(NaN);
    }

    var timeToCompare = dateToCompare.getTime();
    var datesArray; 

    if (dirtyDatesArray == null) {
      datesArray = []; 
    } else if (typeof dirtyDatesArray.forEach === 'function') {
      datesArray = dirtyDatesArray; 
    } else {
      datesArray = Array.prototype.slice.call(dirtyDatesArray);
    }

    var result;
    var minDistance;
    datesArray.forEach(function (dirtyDate) {
      var currentDate = toDate(dirtyDate);

      if (isNaN(currentDate)) {
        result = new Date(NaN);
        minDistance = NaN;
        return;
      }

      var distance = Math.abs(timeToCompare - currentDate.getTime());

      if (result == null || distance < minDistance) {
        result = currentDate;
        minDistance = distance;
      }
    });
    return result;
  }


  function compareAsc(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    var diff = dateLeft.getTime() - dateRight.getTime();

    if (diff < 0) {
      return -1;
    } else if (diff > 0) {
      return 1; 
    } else {
      return diff;
    }
  }


  function compareDesc(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    var diff = dateLeft.getTime() - dateRight.getTime();

    if (diff > 0) {
      return -1;
    } else if (diff < 0) {
      return 1; 
    } else {
      return diff;
    }
  }


  function isValid(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    return !isNaN(date);
  }


  function isSameDay(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeftStartOfDay = startOfDay(dirtyDateLeft);
    var dateRightStartOfDay = startOfDay(dirtyDateRight);
    return dateLeftStartOfDay.getTime() === dateRightStartOfDay.getTime();
  }


  function differenceInBusinessDays(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    if (!isValid(dateLeft) || !isValid(dateRight)) return new Date(NaN);
    var calendarDifference = differenceInCalendarDays(dateLeft, dateRight);
    var sign = calendarDifference < 0 ? -1 : 1;
    var weeks = toInteger(calendarDifference / 7);
    var result = weeks * 5;
    dateRight = addDays(dateRight, weeks * 7); 

    while (!isSameDay(dateLeft, dateRight)) {
      result += isWeekend(dateRight) ? 0 : sign;
      dateRight = addDays(dateRight, sign);
    }

    return result === 0 ? 0 : result;
  }


  function differenceInCalendarISOWeekYears(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    return getISOWeekYear(dirtyDateLeft) - getISOWeekYear(dirtyDateRight);
  }

  var MILLISECONDS_IN_WEEK$6 = 604800000;

  function differenceInCalendarISOWeeks(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var startOfISOWeekLeft = startOfISOWeek(dirtyDateLeft);
    var startOfISOWeekRight = startOfISOWeek(dirtyDateRight);
    var timestampLeft = startOfISOWeekLeft.getTime() - getTimezoneOffsetInMilliseconds(startOfISOWeekLeft);
    var timestampRight = startOfISOWeekRight.getTime() - getTimezoneOffsetInMilliseconds(startOfISOWeekRight); 

    return Math.round((timestampLeft - timestampRight) / MILLISECONDS_IN_WEEK$6);
  }


  function differenceInCalendarMonths(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    var yearDiff = dateLeft.getFullYear() - dateRight.getFullYear();
    var monthDiff = dateLeft.getMonth() - dateRight.getMonth();
    return yearDiff * 12 + monthDiff;
  }


  function getQuarter(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var quarter = Math.floor(date.getMonth() / 3) + 1;
    return quarter;
  }


  function differenceInCalendarQuarters(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    var yearDiff = dateLeft.getFullYear() - dateRight.getFullYear();
    var quarterDiff = getQuarter(dateLeft) - getQuarter(dateRight);
    return yearDiff * 4 + quarterDiff;
  }

  var MILLISECONDS_IN_WEEK$5 = 604800000;

  function differenceInCalendarWeeks(dirtyDateLeft, dirtyDateRight, dirtyOptions) {
    requiredArgs(2, arguments);
    var startOfWeekLeft = startOfWeek(dirtyDateLeft, dirtyOptions);
    var startOfWeekRight = startOfWeek(dirtyDateRight, dirtyOptions);
    var timestampLeft = startOfWeekLeft.getTime() - getTimezoneOffsetInMilliseconds(startOfWeekLeft);
    var timestampRight = startOfWeekRight.getTime() - getTimezoneOffsetInMilliseconds(startOfWeekRight); 

    return Math.round((timestampLeft - timestampRight) / MILLISECONDS_IN_WEEK$5);
  }


  function differenceInCalendarYears(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    return dateLeft.getFullYear() - dateRight.getFullYear();
  }


  function compareLocalAsc(dateLeft, dateRight) {
    var diff = dateLeft.getFullYear() - dateRight.getFullYear() || dateLeft.getMonth() - dateRight.getMonth() || dateLeft.getDate() - dateRight.getDate() || dateLeft.getHours() - dateRight.getHours() || dateLeft.getMinutes() - dateRight.getMinutes() || dateLeft.getSeconds() - dateRight.getSeconds() || dateLeft.getMilliseconds() - dateRight.getMilliseconds();

    if (diff < 0) {
      return -1;
    } else if (diff > 0) {
      return 1; 
    } else {
      return diff;
    }
  }


  function differenceInDays(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    var sign = compareLocalAsc(dateLeft, dateRight);
    var difference = Math.abs(differenceInCalendarDays(dateLeft, dateRight));
    dateLeft.setDate(dateLeft.getDate() - sign * difference); 

    var isLastDayNotFull = compareLocalAsc(dateLeft, dateRight) === -sign;
    var result = sign * (difference - isLastDayNotFull); 

    return result === 0 ? 0 : result;
  }


  function differenceInMilliseconds(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    return dateLeft.getTime() - dateRight.getTime();
  }

  var MILLISECONDS_IN_HOUR$2 = 3600000;

  function differenceInHours(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var diff = differenceInMilliseconds(dirtyDateLeft, dirtyDateRight) / MILLISECONDS_IN_HOUR$2;
    return diff > 0 ? Math.floor(diff) : Math.ceil(diff);
  }


  function subISOWeekYears(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addISOWeekYears(dirtyDate, -amount);
  }


  function differenceInISOWeekYears(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    var sign = compareAsc(dateLeft, dateRight);
    var difference = Math.abs(differenceInCalendarISOWeekYears(dateLeft, dateRight));
    dateLeft = subISOWeekYears(dateLeft, sign * difference); 

    var isLastISOWeekYearNotFull = compareAsc(dateLeft, dateRight) === -sign;
    var result = sign * (difference - isLastISOWeekYearNotFull); 

    return result === 0 ? 0 : result;
  }

  var MILLISECONDS_IN_MINUTE$2 = 60000;

  function differenceInMinutes(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var diff = differenceInMilliseconds(dirtyDateLeft, dirtyDateRight) / MILLISECONDS_IN_MINUTE$2;
    return diff > 0 ? Math.floor(diff) : Math.ceil(diff);
  }


  function differenceInMonths(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    var sign = compareAsc(dateLeft, dateRight);
    var difference = Math.abs(differenceInCalendarMonths(dateLeft, dateRight));
    dateLeft.setMonth(dateLeft.getMonth() - sign * difference); 

    var isLastMonthNotFull = compareAsc(dateLeft, dateRight) === -sign;
    var result = sign * (difference - isLastMonthNotFull); 

    return result === 0 ? 0 : result;
  }


  function differenceInQuarters(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var diff = differenceInMonths(dirtyDateLeft, dirtyDateRight) / 3;
    return diff > 0 ? Math.floor(diff) : Math.ceil(diff);
  }


  function differenceInSeconds(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var diff = differenceInMilliseconds(dirtyDateLeft, dirtyDateRight) / 1000;
    return diff > 0 ? Math.floor(diff) : Math.ceil(diff);
  }


  function differenceInWeeks(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var diff = differenceInDays(dirtyDateLeft, dirtyDateRight) / 7;
    return diff > 0 ? Math.floor(diff) : Math.ceil(diff);
  }


  function differenceInYears(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    var sign = compareAsc(dateLeft, dateRight);
    var difference = Math.abs(differenceInCalendarYears(dateLeft, dateRight)); 

    dateLeft.setFullYear('1584');
    dateRight.setFullYear('1584'); 

    var isLastYearNotFull = compareAsc(dateLeft, dateRight) === -sign;
    var result = sign * (difference - isLastYearNotFull); 

    return result === 0 ? 0 : result;
  }


  function eachDayOfInterval(dirtyInterval, options) {
    requiredArgs(1, arguments);
    var interval = dirtyInterval || {};
    var startDate = toDate(interval.start);
    var endDate = toDate(interval.end);
    var endTime = endDate.getTime(); 

    if (!(startDate.getTime() <= endTime)) {
      throw new RangeError('Invalid interval');
    }

    var dates = [];
    var currentDate = startDate;
    currentDate.setHours(0, 0, 0, 0);
    var step = options && 'step' in options ? Number(options.step) : 1;
    if (step < 1 || isNaN(step)) throw new RangeError('`options.step` must be a number greater than 1');

    while (currentDate.getTime() <= endTime) {
      dates.push(toDate(currentDate));
      currentDate.setDate(currentDate.getDate() + step);
      currentDate.setHours(0, 0, 0, 0);
    }

    return dates;
  }


  function eachHourOfInterval(dirtyInterval, options) {
    requiredArgs(1, arguments);
    var interval = dirtyInterval || {};
    var startDate = toDate(interval.start);
    var endDate = toDate(interval.end);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime(); 

    if (!(startTime <= endTime)) {
      throw new RangeError('Invalid interval');
    }

    var dates = [];
    var currentDate = startDate;
    currentDate.setMinutes(0, 0, 0);
    var step = options && 'step' in options ? Number(options.step) : 1;
    if (step < 1 || isNaN(step)) throw new RangeError('`options.step` must be a number greater than 1');

    while (currentDate.getTime() <= endTime) {
      dates.push(toDate(currentDate));
      currentDate = addHours(currentDate, step);
    }

    return dates;
  }


  function eachMonthOfInterval(dirtyInterval) {
    requiredArgs(1, arguments);
    var interval = dirtyInterval || {};
    var startDate = toDate(interval.start);
    var endDate = toDate(interval.end);
    var endTime = endDate.getTime(); 

    if (!(startDate.getTime() <= endTime)) {
      throw new RangeError('Invalid interval');
    }

    var dates = [];
    var currentDate = startDate;
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setDate(1);

    while (currentDate.getTime() <= endTime) {
      dates.push(toDate(currentDate));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return dates;
  }


  function startOfQuarter(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var currentMonth = date.getMonth();
    var month = currentMonth - currentMonth % 3;
    date.setMonth(month, 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }


  function eachQuarterOfInterval(dirtyInterval) {
    requiredArgs(1, arguments);
    var interval = dirtyInterval || {};
    var startDate = toDate(interval.start);
    var endDate = toDate(interval.end);
    var endTime = endDate.getTime(); 

    if (!(startDate.getTime() <= endTime)) {
      throw new RangeError('Invalid interval');
    }

    var startDateQuarter = startOfQuarter(startDate);
    var endDateQuarter = startOfQuarter(endDate);
    endTime = endDateQuarter.getTime();
    var quarters = [];
    var currentQuarter = startDateQuarter;

    while (currentQuarter.getTime() <= endTime) {
      quarters.push(toDate(currentQuarter));
      currentQuarter = addQuarters(currentQuarter, 1);
    }

    return quarters;
  }


  function eachWeekOfInterval(dirtyInterval, options) {
    requiredArgs(1, arguments);
    var interval = dirtyInterval || {};
    var startDate = toDate(interval.start);
    var endDate = toDate(interval.end);
    var endTime = endDate.getTime(); 

    if (!(startDate.getTime() <= endTime)) {
      throw new RangeError('Invalid interval');
    }

    var startDateWeek = startOfWeek(startDate, options);
    var endDateWeek = startOfWeek(endDate, options); 

    startDateWeek.setHours(15);
    endDateWeek.setHours(15);
    endTime = endDateWeek.getTime();
    var weeks = [];
    var currentWeek = startDateWeek;

    while (currentWeek.getTime() <= endTime) {
      currentWeek.setHours(0);
      weeks.push(toDate(currentWeek));
      currentWeek = addWeeks(currentWeek, 1);
      currentWeek.setHours(15);
    }

    return weeks;
  }


  function eachWeekendOfInterval(interval) {
    requiredArgs(1, arguments);
    var dateInterval = eachDayOfInterval(interval);
    var weekends = [];
    var index = 0;

    while (index < dateInterval.length) {
      var date = dateInterval[index++];

      if (isWeekend(date)) {
        weekends.push(date);
        if (isSunday(date)) index = index + 5;
      }
    }

    return weekends;
  }


  function startOfMonth(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  }


  function endOfMonth(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var month = date.getMonth();
    date.setFullYear(date.getFullYear(), month + 1, 0);
    date.setHours(23, 59, 59, 999);
    return date;
  }


  function eachWeekendOfMonth(dirtyDate) {
    requiredArgs(1, arguments);
    var startDate = startOfMonth(dirtyDate);
    if (isNaN(startDate)) throw new RangeError('The passed date is invalid');
    var endDate = endOfMonth(dirtyDate);
    return eachWeekendOfInterval({
      start: startDate,
      end: endDate
    });
  }


  function startOfYear(dirtyDate) {
    requiredArgs(1, arguments);
    var cleanDate = toDate(dirtyDate);
    var date = new Date(0);
    date.setFullYear(cleanDate.getFullYear(), 0, 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }


  function endOfYear(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var year = date.getFullYear();
    date.setFullYear(year + 1, 0, 0);
    date.setHours(23, 59, 59, 999);
    return date;
  }


  function eachWeekendOfYear(dirtyDate) {
    requiredArgs(1, arguments);
    var startDate = startOfYear(dirtyDate);
    if (isNaN(startDate)) throw new RangeError('The passed date is invalid');
    var endDate = endOfYear(dirtyDate);
    return eachWeekendOfInterval({
      start: startDate,
      end: endDate
    });
  }


  function eachYearOfInterval(dirtyInterval) {
    requiredArgs(1, arguments);
    var interval = dirtyInterval || {};
    var startDate = toDate(interval.start);
    var endDate = toDate(interval.end);
    var endTime = endDate.getTime(); 

    if (!(startDate.getTime() <= endTime)) {
      throw new RangeError('Invalid interval');
    }

    var dates = [];
    var currentDate = startDate;
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setMonth(0, 1);

    while (currentDate.getTime() <= endTime) {
      dates.push(toDate(currentDate));
      currentDate.setFullYear(currentDate.getFullYear() + 1);
    }

    return dates;
  }


  function endOfDay(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    date.setHours(23, 59, 59, 999);
    return date;
  }


  function endOfDecade(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var year = date.getFullYear();
    var decade = 9 + Math.floor(year / 10) * 10;
    date.setFullYear(decade, 11, 31);
    date.setHours(23, 59, 59, 999);
    return date;
  }


  function endOfHour(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    date.setMinutes(59, 59, 999);
    return date;
  }


  function endOfWeek(dirtyDate, dirtyOptions) {
    requiredArgs(1, arguments);
    var options = dirtyOptions || {};
    var locale = options.locale;
    var localeWeekStartsOn = locale && locale.options && locale.options.weekStartsOn;
    var defaultWeekStartsOn = localeWeekStartsOn == null ? 0 : toInteger(localeWeekStartsOn);
    var weekStartsOn = options.weekStartsOn == null ? defaultWeekStartsOn : toInteger(options.weekStartsOn); 

    if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
      throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
    }

    var date = toDate(dirtyDate);
    var day = date.getDay();
    var diff = (day < weekStartsOn ? -7 : 0) + 6 - (day - weekStartsOn);
    date.setDate(date.getDate() + diff);
    date.setHours(23, 59, 59, 999);
    return date;
  }


  function endOfISOWeek(dirtyDate) {
    requiredArgs(1, arguments);
    return endOfWeek(dirtyDate, {
      weekStartsOn: 1
    });
  }


  function endOfISOWeekYear(dirtyDate) {
    requiredArgs(1, arguments);
    var year = getISOWeekYear(dirtyDate);
    var fourthOfJanuaryOfNextYear = new Date(0);
    fourthOfJanuaryOfNextYear.setFullYear(year + 1, 0, 4);
    fourthOfJanuaryOfNextYear.setHours(0, 0, 0, 0);
    var date = startOfISOWeek(fourthOfJanuaryOfNextYear);
    date.setMilliseconds(date.getMilliseconds() - 1);
    return date;
  }


  function endOfMinute(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    date.setSeconds(59, 999);
    return date;
  }


  function endOfQuarter(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var currentMonth = date.getMonth();
    var month = currentMonth - currentMonth % 3 + 3;
    date.setMonth(month, 0);
    date.setHours(23, 59, 59, 999);
    return date;
  }


  function endOfSecond(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    date.setMilliseconds(999);
    return date;
  }


  function endOfToday() {
    return endOfDay(Date.now());
  }

  function endOfTomorrow() {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth();
    var day = now.getDate();
    var date = new Date(0);
    date.setFullYear(year, month, day + 1);
    date.setHours(23, 59, 59, 999);
    return date;
  }

  function endOfYesterday() {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth();
    var day = now.getDate();
    var date = new Date(0);
    date.setFullYear(year, month, day - 1);
    date.setHours(23, 59, 59, 999);
    return date;
  }

  var formatDistanceLocale = {
    lessThanXSeconds: {
      one: 'less than a second',
      other: 'less than {{count}} seconds'
    },
    xSeconds: {
      one: '1 second',
      other: '{{count}} seconds'
    },
    halfAMinute: 'half a minute',
    lessThanXMinutes: {
      one: 'less than a minute',
      other: 'less than {{count}} minutes'
    },
    xMinutes: {
      one: '1 minute',
      other: '{{count}} minutes'
    },
    aboutXHours: {
      one: 'about 1 hour',
      other: 'about {{count}} hours'
    },
    xHours: {
      one: '1 hour',
      other: '{{count}} hours'
    },
    xDays: {
      one: '1 day',
      other: '{{count}} days'
    },
    aboutXWeeks: {
      one: 'about 1 week',
      other: 'about {{count}} weeks'
    },
    xWeeks: {
      one: '1 week',
      other: '{{count}} weeks'
    },
    aboutXMonths: {
      one: 'about 1 month',
      other: 'about {{count}} months'
    },
    xMonths: {
      one: '1 month',
      other: '{{count}} months'
    },
    aboutXYears: {
      one: 'about 1 year',
      other: 'about {{count}} years'
    },
    xYears: {
      one: '1 year',
      other: '{{count}} years'
    },
    overXYears: {
      one: 'over 1 year',
      other: 'over {{count}} years'
    },
    almostXYears: {
      one: 'almost 1 year',
      other: 'almost {{count}} years'
    }
  };
  function formatDistance$1(token, count, options) {
    options = options || {};
    var result;

    if (typeof formatDistanceLocale[token] === 'string') {
      result = formatDistanceLocale[token];
    } else if (count === 1) {
      result = formatDistanceLocale[token].one;
    } else {
      result = formatDistanceLocale[token].other.replace('{{count}}', count);
    }

    if (options.addSuffix) {
      if (options.comparison > 0) {
        return 'in ' + result;
      } else {
        return result + ' ago';
      }
    }

    return result;
  }

  function buildFormatLongFn(args) {
    return function (dirtyOptions) {
      var options = dirtyOptions || {};
      var width = options.width ? String(options.width) : args.defaultWidth;
      var format = args.formats[width] || args.formats[args.defaultWidth];
      return format;
    };
  }

  var dateFormats = {
    full: 'EEEE, MMMM do, y',
    long: 'MMMM do, y',
    medium: 'MMM d, y',
    short: 'MM/dd/yyyy'
  };
  var timeFormats = {
    full: 'h:mm:ss a zzzz',
    long: 'h:mm:ss a z',
    medium: 'h:mm:ss a',
    short: 'h:mm a'
  };
  var dateTimeFormats = {
    full: "{{date}} 'at' {{time}}",
    long: "{{date}} 'at' {{time}}",
    medium: '{{date}}, {{time}}',
    short: '{{date}}, {{time}}'
  };
  var formatLong = {
    date: buildFormatLongFn({
      formats: dateFormats,
      defaultWidth: 'full'
    }),
    time: buildFormatLongFn({
      formats: timeFormats,
      defaultWidth: 'full'
    }),
    dateTime: buildFormatLongFn({
      formats: dateTimeFormats,
      defaultWidth: 'full'
    })
  };

  var formatRelativeLocale = {
    lastWeek: "'last' eeee 'at' p",
    yesterday: "'yesterday at' p",
    today: "'today at' p",
    tomorrow: "'tomorrow at' p",
    nextWeek: "eeee 'at' p",
    other: 'P'
  };
  function formatRelative$1(token, _date, _baseDate, _options) {
    return formatRelativeLocale[token];
  }

  function buildLocalizeFn(args) {
    return function (dirtyIndex, dirtyOptions) {
      var options = dirtyOptions || {};
      var context = options.context ? String(options.context) : 'standalone';
      var valuesArray;

      if (context === 'formatting' && args.formattingValues) {
        var defaultWidth = args.defaultFormattingWidth || args.defaultWidth;
        var width = options.width ? String(options.width) : defaultWidth;
        valuesArray = args.formattingValues[width] || args.formattingValues[defaultWidth];
      } else {
        var _defaultWidth = args.defaultWidth;

        var _width = options.width ? String(options.width) : args.defaultWidth;

        valuesArray = args.values[_width] || args.values[_defaultWidth];
      }

      var index = args.argumentCallback ? args.argumentCallback(dirtyIndex) : dirtyIndex;
      return valuesArray[index];
    };
  }

  var eraValues = {
    narrow: ['B', 'A'],
    abbreviated: ['BC', 'AD'],
    wide: ['Before Christ', 'Anno Domini']
  };
  var quarterValues = {
    narrow: ['1', '2', '3', '4'],
    abbreviated: ['Q1', 'Q2', 'Q3', 'Q4'],
    wide: ['1st quarter', '2nd quarter', '3rd quarter', '4th quarter'] 

  };
  var monthValues = {
    narrow: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
    abbreviated: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    wide: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  };
  var dayValues = {
    narrow: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    short: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    abbreviated: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    wide: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  };
  var dayPeriodValues = {
    narrow: {
      am: 'a',
      pm: 'p',
      midnight: 'mi',
      noon: 'n',
      morning: 'morning',
      afternoon: 'afternoon',
      evening: 'evening',
      night: 'night'
    },
    abbreviated: {
      am: 'AM',
      pm: 'PM',
      midnight: 'midnight',
      noon: 'noon',
      morning: 'morning',
      afternoon: 'afternoon',
      evening: 'evening',
      night: 'night'
    },
    wide: {
      am: 'a.m.',
      pm: 'p.m.',
      midnight: 'midnight',
      noon: 'noon',
      morning: 'morning',
      afternoon: 'afternoon',
      evening: 'evening',
      night: 'night'
    }
  };
  var formattingDayPeriodValues = {
    narrow: {
      am: 'a',
      pm: 'p',
      midnight: 'mi',
      noon: 'n',
      morning: 'in the morning',
      afternoon: 'in the afternoon',
      evening: 'in the evening',
      night: 'at night'
    },
    abbreviated: {
      am: 'AM',
      pm: 'PM',
      midnight: 'midnight',
      noon: 'noon',
      morning: 'in the morning',
      afternoon: 'in the afternoon',
      evening: 'in the evening',
      night: 'at night'
    },
    wide: {
      am: 'a.m.',
      pm: 'p.m.',
      midnight: 'midnight',
      noon: 'noon',
      morning: 'in the morning',
      afternoon: 'in the afternoon',
      evening: 'in the evening',
      night: 'at night'
    }
  };

  function ordinalNumber(dirtyNumber, _dirtyOptions) {
    var number = Number(dirtyNumber); 

    var rem100 = number % 100;

    if (rem100 > 20 || rem100 < 10) {
      switch (rem100 % 10) {
        case 1:
          return number + 'st';

        case 2:
          return number + 'nd';

        case 3:
          return number + 'rd';
      }
    }

    return number + 'th';
  }

  var localize = {
    ordinalNumber: ordinalNumber,
    era: buildLocalizeFn({
      values: eraValues,
      defaultWidth: 'wide'
    }),
    quarter: buildLocalizeFn({
      values: quarterValues,
      defaultWidth: 'wide',
      argumentCallback: function (quarter) {
        return Number(quarter) - 1;
      }
    }),
    month: buildLocalizeFn({
      values: monthValues,
      defaultWidth: 'wide'
    }),
    day: buildLocalizeFn({
      values: dayValues,
      defaultWidth: 'wide'
    }),
    dayPeriod: buildLocalizeFn({
      values: dayPeriodValues,
      defaultWidth: 'wide',
      formattingValues: formattingDayPeriodValues,
      defaultFormattingWidth: 'wide'
    })
  };

  function buildMatchPatternFn(args) {
    return function (dirtyString, dirtyOptions) {
      var string = String(dirtyString);
      var options = dirtyOptions || {};
      var matchResult = string.match(args.matchPattern);

      if (!matchResult) {
        return null;
      }

      var matchedString = matchResult[0];
      var parseResult = string.match(args.parsePattern);

      if (!parseResult) {
        return null;
      }

      var value = args.valueCallback ? args.valueCallback(parseResult[0]) : parseResult[0];
      value = options.valueCallback ? options.valueCallback(value) : value;
      return {
        value: value,
        rest: string.slice(matchedString.length)
      };
    };
  }

  function buildMatchFn(args) {
    return function (dirtyString, dirtyOptions) {
      var string = String(dirtyString);
      var options = dirtyOptions || {};
      var width = options.width;
      var matchPattern = width && args.matchPatterns[width] || args.matchPatterns[args.defaultMatchWidth];
      var matchResult = string.match(matchPattern);

      if (!matchResult) {
        return null;
      }

      var matchedString = matchResult[0];
      var parsePatterns = width && args.parsePatterns[width] || args.parsePatterns[args.defaultParseWidth];
      var value;

      if (Object.prototype.toString.call(parsePatterns) === '[object Array]') {
        value = findIndex(parsePatterns, function (pattern) {
          return pattern.test(matchedString);
        });
      } else {
        value = findKey(parsePatterns, function (pattern) {
          return pattern.test(matchedString);
        });
      }

      value = args.valueCallback ? args.valueCallback(value) : value;
      value = options.valueCallback ? options.valueCallback(value) : value;
      return {
        value: value,
        rest: string.slice(matchedString.length)
      };
    };
  }

  function findKey(object, predicate) {
    for (var key in object) {
      if (object.hasOwnProperty(key) && predicate(object[key])) {
        return key;
      }
    }
  }

  function findIndex(array, predicate) {
    for (var key = 0; key < array.length; key++) {
      if (predicate(array[key])) {
        return key;
      }
    }
  }

  var matchOrdinalNumberPattern = /^(\d+)(th|st|nd|rd)?/i;
  var parseOrdinalNumberPattern = /\d+/i;
  var matchEraPatterns = {
    narrow: /^(b|a)/i,
    abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
    wide: /^(before christ|before common era|anno domini|common era)/i
  };
  var parseEraPatterns = {
    any: [/^b/i, /^(a|c)/i]
  };
  var matchQuarterPatterns = {
    narrow: /^[1234]/i,
    abbreviated: /^q[1234]/i,
    wide: /^[1234](th|st|nd|rd)? quarter/i
  };
  var parseQuarterPatterns = {
    any: [/1/i, /2/i, /3/i, /4/i]
  };
  var matchMonthPatterns = {
    narrow: /^[jfmasond]/i,
    abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
    wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
  };
  var parseMonthPatterns = {
    narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
    any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^may/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
  };
  var matchDayPatterns = {
    narrow: /^[smtwf]/i,
    short: /^(su|mo|tu|we|th|fr|sa)/i,
    abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
    wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
  };
  var parseDayPatterns = {
    narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
    any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
  };
  var matchDayPeriodPatterns = {
    narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
    any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
  };
  var parseDayPeriodPatterns = {
    any: {
      am: /^a/i,
      pm: /^p/i,
      midnight: /^mi/i,
      noon: /^no/i,
      morning: /morning/i,
      afternoon: /afternoon/i,
      evening: /evening/i,
      night: /night/i
    }
  };
  var match = {
    ordinalNumber: buildMatchPatternFn({
      matchPattern: matchOrdinalNumberPattern,
      parsePattern: parseOrdinalNumberPattern,
      valueCallback: function (value) {
        return parseInt(value, 10);
      }
    }),
    era: buildMatchFn({
      matchPatterns: matchEraPatterns,
      defaultMatchWidth: 'wide',
      parsePatterns: parseEraPatterns,
      defaultParseWidth: 'any'
    }),
    quarter: buildMatchFn({
      matchPatterns: matchQuarterPatterns,
      defaultMatchWidth: 'wide',
      parsePatterns: parseQuarterPatterns,
      defaultParseWidth: 'any',
      valueCallback: function (index) {
        return index + 1;
      }
    }),
    month: buildMatchFn({
      matchPatterns: matchMonthPatterns,
      defaultMatchWidth: 'wide',
      parsePatterns: parseMonthPatterns,
      defaultParseWidth: 'any'
    }),
    day: buildMatchFn({
      matchPatterns: matchDayPatterns,
      defaultMatchWidth: 'wide',
      parsePatterns: parseDayPatterns,
      defaultParseWidth: 'any'
    }),
    dayPeriod: buildMatchFn({
      matchPatterns: matchDayPeriodPatterns,
      defaultMatchWidth: 'any',
      parsePatterns: parseDayPeriodPatterns,
      defaultParseWidth: 'any'
    })
  };


  var locale = {
    code: 'en-US',
    formatDistance: formatDistance$1,
    formatLong: formatLong,
    formatRelative: formatRelative$1,
    localize: localize,
    match: match,
    options: {
      weekStartsOn: 0
      ,
      firstWeekContainsDate: 1
    }
  };


  function subMilliseconds(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addMilliseconds(dirtyDate, -amount);
  }

  function addLeadingZeros(number, targetLength) {
    var sign = number < 0 ? '-' : '';
    var output = Math.abs(number).toString();

    while (output.length < targetLength) {
      output = '0' + output;
    }

    return sign + output;
  }


  var formatters$1 = {
    y: function (date, token) {
      var signedYear = date.getUTCFullYear(); 

      var year = signedYear > 0 ? signedYear : 1 - signedYear;
      return addLeadingZeros(token === 'yy' ? year % 100 : year, token.length);
    },
    M: function (date, token) {
      var month = date.getUTCMonth();
      return token === 'M' ? String(month + 1) : addLeadingZeros(month + 1, 2);
    },
    d: function (date, token) {
      return addLeadingZeros(date.getUTCDate(), token.length);
    },
    a: function (date, token) {
      var dayPeriodEnumValue = date.getUTCHours() / 12 >= 1 ? 'pm' : 'am';

      switch (token) {
        case 'a':
        case 'aa':
        case 'aaa':
          return dayPeriodEnumValue.toUpperCase();

        case 'aaaaa':
          return dayPeriodEnumValue[0];

        case 'aaaa':
        default:
          return dayPeriodEnumValue === 'am' ? 'a.m.' : 'p.m.';
      }
    },
    h: function (date, token) {
      return addLeadingZeros(date.getUTCHours() % 12 || 12, token.length);
    },
    H: function (date, token) {
      return addLeadingZeros(date.getUTCHours(), token.length);
    },
    m: function (date, token) {
      return addLeadingZeros(date.getUTCMinutes(), token.length);
    },
    s: function (date, token) {
      return addLeadingZeros(date.getUTCSeconds(), token.length);
    },
    S: function (date, token) {
      var numberOfDigits = token.length;
      var milliseconds = date.getUTCMilliseconds();
      var fractionalSeconds = Math.floor(milliseconds * Math.pow(10, numberOfDigits - 3));
      return addLeadingZeros(fractionalSeconds, token.length);
    }
  };

  var MILLISECONDS_IN_DAY$1 = 86400000; 

  function getUTCDayOfYear(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var timestamp = date.getTime();
    date.setUTCMonth(0, 1);
    date.setUTCHours(0, 0, 0, 0);
    var startOfYearTimestamp = date.getTime();
    var difference = timestamp - startOfYearTimestamp;
    return Math.floor(difference / MILLISECONDS_IN_DAY$1) + 1;
  }


  function startOfUTCISOWeek(dirtyDate) {
    requiredArgs(1, arguments);
    var weekStartsOn = 1;
    var date = toDate(dirtyDate);
    var day = date.getUTCDay();
    var diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
    date.setUTCDate(date.getUTCDate() - diff);
    date.setUTCHours(0, 0, 0, 0);
    return date;
  }


  function getUTCISOWeekYear(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var year = date.getUTCFullYear();
    var fourthOfJanuaryOfNextYear = new Date(0);
    fourthOfJanuaryOfNextYear.setUTCFullYear(year + 1, 0, 4);
    fourthOfJanuaryOfNextYear.setUTCHours(0, 0, 0, 0);
    var startOfNextYear = startOfUTCISOWeek(fourthOfJanuaryOfNextYear);
    var fourthOfJanuaryOfThisYear = new Date(0);
    fourthOfJanuaryOfThisYear.setUTCFullYear(year, 0, 4);
    fourthOfJanuaryOfThisYear.setUTCHours(0, 0, 0, 0);
    var startOfThisYear = startOfUTCISOWeek(fourthOfJanuaryOfThisYear);

    if (date.getTime() >= startOfNextYear.getTime()) {
      return year + 1;
    } else if (date.getTime() >= startOfThisYear.getTime()) {
      return year;
    } else {
      return year - 1;
    }
  }


  function startOfUTCISOWeekYear(dirtyDate) {
    requiredArgs(1, arguments);
    var year = getUTCISOWeekYear(dirtyDate);
    var fourthOfJanuary = new Date(0);
    fourthOfJanuary.setUTCFullYear(year, 0, 4);
    fourthOfJanuary.setUTCHours(0, 0, 0, 0);
    var date = startOfUTCISOWeek(fourthOfJanuary);
    return date;
  }

  var MILLISECONDS_IN_WEEK$4 = 604800000; 

  function getUTCISOWeek(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var diff = startOfUTCISOWeek(date).getTime() - startOfUTCISOWeekYear(date).getTime(); 

    return Math.round(diff / MILLISECONDS_IN_WEEK$4) + 1;
  }


  function startOfUTCWeek(dirtyDate, dirtyOptions) {
    requiredArgs(1, arguments);
    var options = dirtyOptions || {};
    var locale = options.locale;
    var localeWeekStartsOn = locale && locale.options && locale.options.weekStartsOn;
    var defaultWeekStartsOn = localeWeekStartsOn == null ? 0 : toInteger(localeWeekStartsOn);
    var weekStartsOn = options.weekStartsOn == null ? defaultWeekStartsOn : toInteger(options.weekStartsOn); 

    if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
      throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
    }

    var date = toDate(dirtyDate);
    var day = date.getUTCDay();
    var diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
    date.setUTCDate(date.getUTCDate() - diff);
    date.setUTCHours(0, 0, 0, 0);
    return date;
  }


  function getUTCWeekYear(dirtyDate, dirtyOptions) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate, dirtyOptions);
    var year = date.getUTCFullYear();
    var options = dirtyOptions || {};
    var locale = options.locale;
    var localeFirstWeekContainsDate = locale && locale.options && locale.options.firstWeekContainsDate;
    var defaultFirstWeekContainsDate = localeFirstWeekContainsDate == null ? 1 : toInteger(localeFirstWeekContainsDate);
    var firstWeekContainsDate = options.firstWeekContainsDate == null ? defaultFirstWeekContainsDate : toInteger(options.firstWeekContainsDate); 

    if (!(firstWeekContainsDate >= 1 && firstWeekContainsDate <= 7)) {
      throw new RangeError('firstWeekContainsDate must be between 1 and 7 inclusively');
    }

    var firstWeekOfNextYear = new Date(0);
    firstWeekOfNextYear.setUTCFullYear(year + 1, 0, firstWeekContainsDate);
    firstWeekOfNextYear.setUTCHours(0, 0, 0, 0);
    var startOfNextYear = startOfUTCWeek(firstWeekOfNextYear, dirtyOptions);
    var firstWeekOfThisYear = new Date(0);
    firstWeekOfThisYear.setUTCFullYear(year, 0, firstWeekContainsDate);
    firstWeekOfThisYear.setUTCHours(0, 0, 0, 0);
    var startOfThisYear = startOfUTCWeek(firstWeekOfThisYear, dirtyOptions);

    if (date.getTime() >= startOfNextYear.getTime()) {
      return year + 1;
    } else if (date.getTime() >= startOfThisYear.getTime()) {
      return year;
    } else {
      return year - 1;
    }
  }


  function startOfUTCWeekYear(dirtyDate, dirtyOptions) {
    requiredArgs(1, arguments);
    var options = dirtyOptions || {};
    var locale = options.locale;
    var localeFirstWeekContainsDate = locale && locale.options && locale.options.firstWeekContainsDate;
    var defaultFirstWeekContainsDate = localeFirstWeekContainsDate == null ? 1 : toInteger(localeFirstWeekContainsDate);
    var firstWeekContainsDate = options.firstWeekContainsDate == null ? defaultFirstWeekContainsDate : toInteger(options.firstWeekContainsDate);
    var year = getUTCWeekYear(dirtyDate, dirtyOptions);
    var firstWeek = new Date(0);
    firstWeek.setUTCFullYear(year, 0, firstWeekContainsDate);
    firstWeek.setUTCHours(0, 0, 0, 0);
    var date = startOfUTCWeek(firstWeek, dirtyOptions);
    return date;
  }

  var MILLISECONDS_IN_WEEK$3 = 604800000; 

  function getUTCWeek(dirtyDate, options) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var diff = startOfUTCWeek(date, options).getTime() - startOfUTCWeekYear(date, options).getTime(); 

    return Math.round(diff / MILLISECONDS_IN_WEEK$3) + 1;
  }

  var dayPeriodEnum = {
    am: 'am',
    pm: 'pm',
    midnight: 'midnight',
    noon: 'noon',
    morning: 'morning',
    afternoon: 'afternoon',
    evening: 'evening',
    night: 'night'

  };
  var formatters = {
    G: function (date, token, localize) {
      var era = date.getUTCFullYear() > 0 ? 1 : 0;

      switch (token) {
        case 'G':
        case 'GG':
        case 'GGG':
          return localize.era(era, {
            width: 'abbreviated'
          });

        case 'GGGGG':
          return localize.era(era, {
            width: 'narrow'
          });

        case 'GGGG':
        default:
          return localize.era(era, {
            width: 'wide'
          });
      }
    },
    y: function (date, token, localize) {
      if (token === 'yo') {
        var signedYear = date.getUTCFullYear(); 

        var year = signedYear > 0 ? signedYear : 1 - signedYear;
        return localize.ordinalNumber(year, {
          unit: 'year'
        });
      }

      return formatters$1.y(date, token);
    },
    Y: function (date, token, localize, options) {
      var signedWeekYear = getUTCWeekYear(date, options); 

      var weekYear = signedWeekYear > 0 ? signedWeekYear : 1 - signedWeekYear; 

      if (token === 'YY') {
        var twoDigitYear = weekYear % 100;
        return addLeadingZeros(twoDigitYear, 2);
      } 


      if (token === 'Yo') {
        return localize.ordinalNumber(weekYear, {
          unit: 'year'
        });
      } 


      return addLeadingZeros(weekYear, token.length);
    },
    R: function (date, token) {
      var isoWeekYear = getUTCISOWeekYear(date); 

      return addLeadingZeros(isoWeekYear, token.length);
    },
    u: function (date, token) {
      var year = date.getUTCFullYear();
      return addLeadingZeros(year, token.length);
    },
    Q: function (date, token, localize) {
      var quarter = Math.ceil((date.getUTCMonth() + 1) / 3);

      switch (token) {
        case 'Q':
          return String(quarter);

        case 'QQ':
          return addLeadingZeros(quarter, 2);

        case 'Qo':
          return localize.ordinalNumber(quarter, {
            unit: 'quarter'
          });

        case 'QQQ':
          return localize.quarter(quarter, {
            width: 'abbreviated',
            context: 'formatting'
          });

        case 'QQQQQ':
          return localize.quarter(quarter, {
            width: 'narrow',
            context: 'formatting'
          });

        case 'QQQQ':
        default:
          return localize.quarter(quarter, {
            width: 'wide',
            context: 'formatting'
          });
      }
    },
    q: function (date, token, localize) {
      var quarter = Math.ceil((date.getUTCMonth() + 1) / 3);

      switch (token) {
        case 'q':
          return String(quarter);

        case 'qq':
          return addLeadingZeros(quarter, 2);

        case 'qo':
          return localize.ordinalNumber(quarter, {
            unit: 'quarter'
          });

        case 'qqq':
          return localize.quarter(quarter, {
            width: 'abbreviated',
            context: 'standalone'
          });

        case 'qqqqq':
          return localize.quarter(quarter, {
            width: 'narrow',
            context: 'standalone'
          });

        case 'qqqq':
        default:
          return localize.quarter(quarter, {
            width: 'wide',
            context: 'standalone'
          });
      }
    },
    M: function (date, token, localize) {
      var month = date.getUTCMonth();

      switch (token) {
        case 'M':
        case 'MM':
          return formatters$1.M(date, token);

        case 'Mo':
          return localize.ordinalNumber(month + 1, {
            unit: 'month'
          });

        case 'MMM':
          return localize.month(month, {
            width: 'abbreviated',
            context: 'formatting'
          });

        case 'MMMMM':
          return localize.month(month, {
            width: 'narrow',
            context: 'formatting'
          });

        case 'MMMM':
        default:
          return localize.month(month, {
            width: 'wide',
            context: 'formatting'
          });
      }
    },
    L: function (date, token, localize) {
      var month = date.getUTCMonth();

      switch (token) {
        case 'L':
          return String(month + 1);

        case 'LL':
          return addLeadingZeros(month + 1, 2);

        case 'Lo':
          return localize.ordinalNumber(month + 1, {
            unit: 'month'
          });

        case 'LLL':
          return localize.month(month, {
            width: 'abbreviated',
            context: 'standalone'
          });

        case 'LLLLL':
          return localize.month(month, {
            width: 'narrow',
            context: 'standalone'
          });

        case 'LLLL':
        default:
          return localize.month(month, {
            width: 'wide',
            context: 'standalone'
          });
      }
    },
    w: function (date, token, localize, options) {
      var week = getUTCWeek(date, options);

      if (token === 'wo') {
        return localize.ordinalNumber(week, {
          unit: 'week'
        });
      }

      return addLeadingZeros(week, token.length);
    },
    I: function (date, token, localize) {
      var isoWeek = getUTCISOWeek(date);

      if (token === 'Io') {
        return localize.ordinalNumber(isoWeek, {
          unit: 'week'
        });
      }

      return addLeadingZeros(isoWeek, token.length);
    },
    d: function (date, token, localize) {
      if (token === 'do') {
        return localize.ordinalNumber(date.getUTCDate(), {
          unit: 'date'
        });
      }

      return formatters$1.d(date, token);
    },
    D: function (date, token, localize) {
      var dayOfYear = getUTCDayOfYear(date);

      if (token === 'Do') {
        return localize.ordinalNumber(dayOfYear, {
          unit: 'dayOfYear'
        });
      }

      return addLeadingZeros(dayOfYear, token.length);
    },
    E: function (date, token, localize) {
      var dayOfWeek = date.getUTCDay();

      switch (token) {
        case 'E':
        case 'EE':
        case 'EEE':
          return localize.day(dayOfWeek, {
            width: 'abbreviated',
            context: 'formatting'
          });

        case 'EEEEE':
          return localize.day(dayOfWeek, {
            width: 'narrow',
            context: 'formatting'
          });

        case 'EEEEEE':
          return localize.day(dayOfWeek, {
            width: 'short',
            context: 'formatting'
          });

        case 'EEEE':
        default:
          return localize.day(dayOfWeek, {
            width: 'wide',
            context: 'formatting'
          });
      }
    },
    e: function (date, token, localize, options) {
      var dayOfWeek = date.getUTCDay();
      var localDayOfWeek = (dayOfWeek - options.weekStartsOn + 8) % 7 || 7;

      switch (token) {
        case 'e':
          return String(localDayOfWeek);

        case 'ee':
          return addLeadingZeros(localDayOfWeek, 2);

        case 'eo':
          return localize.ordinalNumber(localDayOfWeek, {
            unit: 'day'
          });

        case 'eee':
          return localize.day(dayOfWeek, {
            width: 'abbreviated',
            context: 'formatting'
          });

        case 'eeeee':
          return localize.day(dayOfWeek, {
            width: 'narrow',
            context: 'formatting'
          });

        case 'eeeeee':
          return localize.day(dayOfWeek, {
            width: 'short',
            context: 'formatting'
          });

        case 'eeee':
        default:
          return localize.day(dayOfWeek, {
            width: 'wide',
            context: 'formatting'
          });
      }
    },
    c: function (date, token, localize, options) {
      var dayOfWeek = date.getUTCDay();
      var localDayOfWeek = (dayOfWeek - options.weekStartsOn + 8) % 7 || 7;

      switch (token) {
        case 'c':
          return String(localDayOfWeek);

        case 'cc':
          return addLeadingZeros(localDayOfWeek, token.length);

        case 'co':
          return localize.ordinalNumber(localDayOfWeek, {
            unit: 'day'
          });

        case 'ccc':
          return localize.day(dayOfWeek, {
            width: 'abbreviated',
            context: 'standalone'
          });

        case 'ccccc':
          return localize.day(dayOfWeek, {
            width: 'narrow',
            context: 'standalone'
          });

        case 'cccccc':
          return localize.day(dayOfWeek, {
            width: 'short',
            context: 'standalone'
          });

        case 'cccc':
        default:
          return localize.day(dayOfWeek, {
            width: 'wide',
            context: 'standalone'
          });
      }
    },
    i: function (date, token, localize) {
      var dayOfWeek = date.getUTCDay();
      var isoDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;

      switch (token) {
        case 'i':
          return String(isoDayOfWeek);

        case 'ii':
          return addLeadingZeros(isoDayOfWeek, token.length);

        case 'io':
          return localize.ordinalNumber(isoDayOfWeek, {
            unit: 'day'
          });

        case 'iii':
          return localize.day(dayOfWeek, {
            width: 'abbreviated',
            context: 'formatting'
          });

        case 'iiiii':
          return localize.day(dayOfWeek, {
            width: 'narrow',
            context: 'formatting'
          });

        case 'iiiiii':
          return localize.day(dayOfWeek, {
            width: 'short',
            context: 'formatting'
          });

        case 'iiii':
        default:
          return localize.day(dayOfWeek, {
            width: 'wide',
            context: 'formatting'
          });
      }
    },
    a: function (date, token, localize) {
      var hours = date.getUTCHours();
      var dayPeriodEnumValue = hours / 12 >= 1 ? 'pm' : 'am';

      switch (token) {
        case 'a':
        case 'aa':
        case 'aaa':
          return localize.dayPeriod(dayPeriodEnumValue, {
            width: 'abbreviated',
            context: 'formatting'
          });

        case 'aaaaa':
          return localize.dayPeriod(dayPeriodEnumValue, {
            width: 'narrow',
            context: 'formatting'
          });

        case 'aaaa':
        default:
          return localize.dayPeriod(dayPeriodEnumValue, {
            width: 'wide',
            context: 'formatting'
          });
      }
    },
    b: function (date, token, localize) {
      var hours = date.getUTCHours();
      var dayPeriodEnumValue;

      if (hours === 12) {
        dayPeriodEnumValue = dayPeriodEnum.noon;
      } else if (hours === 0) {
        dayPeriodEnumValue = dayPeriodEnum.midnight;
      } else {
        dayPeriodEnumValue = hours / 12 >= 1 ? 'pm' : 'am';
      }

      switch (token) {
        case 'b':
        case 'bb':
        case 'bbb':
          return localize.dayPeriod(dayPeriodEnumValue, {
            width: 'abbreviated',
            context: 'formatting'
          });

        case 'bbbbb':
          return localize.dayPeriod(dayPeriodEnumValue, {
            width: 'narrow',
            context: 'formatting'
          });

        case 'bbbb':
        default:
          return localize.dayPeriod(dayPeriodEnumValue, {
            width: 'wide',
            context: 'formatting'
          });
      }
    },
    B: function (date, token, localize) {
      var hours = date.getUTCHours();
      var dayPeriodEnumValue;

      if (hours >= 17) {
        dayPeriodEnumValue = dayPeriodEnum.evening;
      } else if (hours >= 12) {
        dayPeriodEnumValue = dayPeriodEnum.afternoon;
      } else if (hours >= 4) {
        dayPeriodEnumValue = dayPeriodEnum.morning;
      } else {
        dayPeriodEnumValue = dayPeriodEnum.night;
      }

      switch (token) {
        case 'B':
        case 'BB':
        case 'BBB':
          return localize.dayPeriod(dayPeriodEnumValue, {
            width: 'abbreviated',
            context: 'formatting'
          });

        case 'BBBBB':
          return localize.dayPeriod(dayPeriodEnumValue, {
            width: 'narrow',
            context: 'formatting'
          });

        case 'BBBB':
        default:
          return localize.dayPeriod(dayPeriodEnumValue, {
            width: 'wide',
            context: 'formatting'
          });
      }
    },
    h: function (date, token, localize) {
      if (token === 'ho') {
        var hours = date.getUTCHours() % 12;
        if (hours === 0) hours = 12;
        return localize.ordinalNumber(hours, {
          unit: 'hour'
        });
      }

      return formatters$1.h(date, token);
    },
    H: function (date, token, localize) {
      if (token === 'Ho') {
        return localize.ordinalNumber(date.getUTCHours(), {
          unit: 'hour'
        });
      }

      return formatters$1.H(date, token);
    },
    K: function (date, token, localize) {
      var hours = date.getUTCHours() % 12;

      if (token === 'Ko') {
        return localize.ordinalNumber(hours, {
          unit: 'hour'
        });
      }

      return addLeadingZeros(hours, token.length);
    },
    k: function (date, token, localize) {
      var hours = date.getUTCHours();
      if (hours === 0) hours = 24;

      if (token === 'ko') {
        return localize.ordinalNumber(hours, {
          unit: 'hour'
        });
      }

      return addLeadingZeros(hours, token.length);
    },
    m: function (date, token, localize) {
      if (token === 'mo') {
        return localize.ordinalNumber(date.getUTCMinutes(), {
          unit: 'minute'
        });
      }

      return formatters$1.m(date, token);
    },
    s: function (date, token, localize) {
      if (token === 'so') {
        return localize.ordinalNumber(date.getUTCSeconds(), {
          unit: 'second'
        });
      }

      return formatters$1.s(date, token);
    },
    S: function (date, token) {
      return formatters$1.S(date, token);
    },
    X: function (date, token, _localize, options) {
      var originalDate = options._originalDate || date;
      var timezoneOffset = originalDate.getTimezoneOffset();

      if (timezoneOffset === 0) {
        return 'Z';
      }

      switch (token) {
        case 'X':
          return formatTimezoneWithOptionalMinutes(timezoneOffset);

        case 'XXXX':
        case 'XX':
          return formatTimezone(timezoneOffset);

        case 'XXXXX':
        case 'XXX': 

        default:
          return formatTimezone(timezoneOffset, ':');
      }
    },
    x: function (date, token, _localize, options) {
      var originalDate = options._originalDate || date;
      var timezoneOffset = originalDate.getTimezoneOffset();

      switch (token) {
        case 'x':
          return formatTimezoneWithOptionalMinutes(timezoneOffset);

        case 'xxxx':
        case 'xx':
          return formatTimezone(timezoneOffset);

        case 'xxxxx':
        case 'xxx': 

        default:
          return formatTimezone(timezoneOffset, ':');
      }
    },
    O: function (date, token, _localize, options) {
      var originalDate = options._originalDate || date;
      var timezoneOffset = originalDate.getTimezoneOffset();

      switch (token) {
        case 'O':
        case 'OO':
        case 'OOO':
          return 'GMT' + formatTimezoneShort(timezoneOffset, ':');

        case 'OOOO':
        default:
          return 'GMT' + formatTimezone(timezoneOffset, ':');
      }
    },
    z: function (date, token, _localize, options) {
      var originalDate = options._originalDate || date;
      var timezoneOffset = originalDate.getTimezoneOffset();

      switch (token) {
        case 'z':
        case 'zz':
        case 'zzz':
          return 'GMT' + formatTimezoneShort(timezoneOffset, ':');

        case 'zzzz':
        default:
          return 'GMT' + formatTimezone(timezoneOffset, ':');
      }
    },
    t: function (date, token, _localize, options) {
      var originalDate = options._originalDate || date;
      var timestamp = Math.floor(originalDate.getTime() / 1000);
      return addLeadingZeros(timestamp, token.length);
    },
    T: function (date, token, _localize, options) {
      var originalDate = options._originalDate || date;
      var timestamp = originalDate.getTime();
      return addLeadingZeros(timestamp, token.length);
    }
  };

  function formatTimezoneShort(offset, dirtyDelimiter) {
    var sign = offset > 0 ? '-' : '+';
    var absOffset = Math.abs(offset);
    var hours = Math.floor(absOffset / 60);
    var minutes = absOffset % 60;

    if (minutes === 0) {
      return sign + String(hours);
    }

    var delimiter = dirtyDelimiter || '';
    return sign + String(hours) + delimiter + addLeadingZeros(minutes, 2);
  }

  function formatTimezoneWithOptionalMinutes(offset, dirtyDelimiter) {
    if (offset % 60 === 0) {
      var sign = offset > 0 ? '-' : '+';
      return sign + addLeadingZeros(Math.abs(offset) / 60, 2);
    }

    return formatTimezone(offset, dirtyDelimiter);
  }

  function formatTimezone(offset, dirtyDelimiter) {
    var delimiter = dirtyDelimiter || '';
    var sign = offset > 0 ? '-' : '+';
    var absOffset = Math.abs(offset);
    var hours = addLeadingZeros(Math.floor(absOffset / 60), 2);
    var minutes = addLeadingZeros(absOffset % 60, 2);
    return sign + hours + delimiter + minutes;
  }

  function dateLongFormatter(pattern, formatLong) {
    switch (pattern) {
      case 'P':
        return formatLong.date({
          width: 'short'
        });

      case 'PP':
        return formatLong.date({
          width: 'medium'
        });

      case 'PPP':
        return formatLong.date({
          width: 'long'
        });

      case 'PPPP':
      default:
        return formatLong.date({
          width: 'full'
        });
    }
  }

  function timeLongFormatter(pattern, formatLong) {
    switch (pattern) {
      case 'p':
        return formatLong.time({
          width: 'short'
        });

      case 'pp':
        return formatLong.time({
          width: 'medium'
        });

      case 'ppp':
        return formatLong.time({
          width: 'long'
        });

      case 'pppp':
      default:
        return formatLong.time({
          width: 'full'
        });
    }
  }

  function dateTimeLongFormatter(pattern, formatLong) {
    var matchResult = pattern.match(/(P+)(p+)?/);
    var datePattern = matchResult[1];
    var timePattern = matchResult[2];

    if (!timePattern) {
      return dateLongFormatter(pattern, formatLong);
    }

    var dateTimeFormat;

    switch (datePattern) {
      case 'P':
        dateTimeFormat = formatLong.dateTime({
          width: 'short'
        });
        break;

      case 'PP':
        dateTimeFormat = formatLong.dateTime({
          width: 'medium'
        });
        break;

      case 'PPP':
        dateTimeFormat = formatLong.dateTime({
          width: 'long'
        });
        break;

      case 'PPPP':
      default:
        dateTimeFormat = formatLong.dateTime({
          width: 'full'
        });
        break;
    }

    return dateTimeFormat.replace('{{date}}', dateLongFormatter(datePattern, formatLong)).replace('{{time}}', timeLongFormatter(timePattern, formatLong));
  }

  var longFormatters = {
    p: timeLongFormatter,
    P: dateTimeLongFormatter
  };

  var protectedDayOfYearTokens = ['D', 'DD'];
  var protectedWeekYearTokens = ['YY', 'YYYY'];
  function isProtectedDayOfYearToken(token) {
    return protectedDayOfYearTokens.indexOf(token) !== -1;
  }
  function isProtectedWeekYearToken(token) {
    return protectedWeekYearTokens.indexOf(token) !== -1;
  }
  function throwProtectedError(token, format, input) {
    if (token === 'YYYY') {
      throw new RangeError("Use `yyyy` instead of `YYYY` (in `".concat(format, "`) for formatting years to the input `").concat(input, "`; see: https://git.io/fxCyr"));
    } else if (token === 'YY') {
      throw new RangeError("Use `yy` instead of `YY` (in `".concat(format, "`) for formatting years to the input `").concat(input, "`; see: https://git.io/fxCyr"));
    } else if (token === 'D') {
      throw new RangeError("Use `d` instead of `D` (in `".concat(format, "`) for formatting days of the month to the input `").concat(input, "`; see: https://git.io/fxCyr"));
    } else if (token === 'DD') {
      throw new RangeError("Use `dd` instead of `DD` (in `".concat(format, "`) for formatting days of the month to the input `").concat(input, "`; see: https://git.io/fxCyr"));
    }
  }


  var formattingTokensRegExp$2 = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g; 

  var longFormattingTokensRegExp$1 = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;
  var escapedStringRegExp$2 = /^'([^]*?)'?$/;
  var doubleQuoteRegExp$2 = /''/g;
  var unescapedLatinCharacterRegExp$2 = /[a-zA-Z]/;

  function format(dirtyDate, dirtyFormatStr, dirtyOptions) {
    requiredArgs(2, arguments);
    var formatStr = String(dirtyFormatStr);
    var options = dirtyOptions || {};
    var locale$1 = options.locale || locale;
    var localeFirstWeekContainsDate = locale$1.options && locale$1.options.firstWeekContainsDate;
    var defaultFirstWeekContainsDate = localeFirstWeekContainsDate == null ? 1 : toInteger(localeFirstWeekContainsDate);
    var firstWeekContainsDate = options.firstWeekContainsDate == null ? defaultFirstWeekContainsDate : toInteger(options.firstWeekContainsDate); 

    if (!(firstWeekContainsDate >= 1 && firstWeekContainsDate <= 7)) {
      throw new RangeError('firstWeekContainsDate must be between 1 and 7 inclusively');
    }

    var localeWeekStartsOn = locale$1.options && locale$1.options.weekStartsOn;
    var defaultWeekStartsOn = localeWeekStartsOn == null ? 0 : toInteger(localeWeekStartsOn);
    var weekStartsOn = options.weekStartsOn == null ? defaultWeekStartsOn : toInteger(options.weekStartsOn); 

    if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
      throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
    }

    if (!locale$1.localize) {
      throw new RangeError('locale must contain localize property');
    }

    if (!locale$1.formatLong) {
      throw new RangeError('locale must contain formatLong property');
    }

    var originalDate = toDate(dirtyDate);

    if (!isValid(originalDate)) {
      throw new RangeError('Invalid time value');
    } 


    var timezoneOffset = getTimezoneOffsetInMilliseconds(originalDate);
    var utcDate = subMilliseconds(originalDate, timezoneOffset);
    var formatterOptions = {
      firstWeekContainsDate: firstWeekContainsDate,
      weekStartsOn: weekStartsOn,
      locale: locale$1,
      _originalDate: originalDate
    };
    var result = formatStr.match(longFormattingTokensRegExp$1).map(function (substring) {
      var firstCharacter = substring[0];

      if (firstCharacter === 'p' || firstCharacter === 'P') {
        var longFormatter = longFormatters[firstCharacter];
        return longFormatter(substring, locale$1.formatLong, formatterOptions);
      }

      return substring;
    }).join('').match(formattingTokensRegExp$2).map(function (substring) {
      if (substring === "''") {
        return "'";
      }

      var firstCharacter = substring[0];

      if (firstCharacter === "'") {
        return cleanEscapedString$2(substring);
      }

      var formatter = formatters[firstCharacter];

      if (formatter) {
        if (!options.useAdditionalWeekYearTokens && isProtectedWeekYearToken(substring)) {
          throwProtectedError(substring, dirtyFormatStr, dirtyDate);
        }

        if (!options.useAdditionalDayOfYearTokens && isProtectedDayOfYearToken(substring)) {
          throwProtectedError(substring, dirtyFormatStr, dirtyDate);
        }

        return formatter(utcDate, substring, locale$1.localize, formatterOptions);
      }

      if (firstCharacter.match(unescapedLatinCharacterRegExp$2)) {
        throw new RangeError('Format string contains an unescaped latin alphabet character `' + firstCharacter + '`');
      }

      return substring;
    }).join('');
    return result;
  }

  function cleanEscapedString$2(input) {
    return input.match(escapedStringRegExp$2)[1].replace(doubleQuoteRegExp$2, "'");
  }

  function assign(target, dirtyObject) {
    if (target == null) {
      throw new TypeError('assign requires that input parameter not be null or undefined');
    }

    dirtyObject = dirtyObject || {};

    for (var property in dirtyObject) {
      if (dirtyObject.hasOwnProperty(property)) {
        target[property] = dirtyObject[property];
      }
    }

    return target;
  }

  function cloneObject(dirtyObject) {
    return assign({}, dirtyObject);
  }

  var MINUTES_IN_DAY$1 = 1440;
  var MINUTES_IN_ALMOST_TWO_DAYS = 2520;
  var MINUTES_IN_MONTH$1 = 43200;
  var MINUTES_IN_TWO_MONTHS = 86400;

  function formatDistance(dirtyDate, dirtyBaseDate, dirtyOptions) {
    requiredArgs(2, arguments);
    var options = dirtyOptions || {};
    var locale$1 = options.locale || locale;

    if (!locale$1.formatDistance) {
      throw new RangeError('locale must contain formatDistance property');
    }

    var comparison = compareAsc(dirtyDate, dirtyBaseDate);

    if (isNaN(comparison)) {
      throw new RangeError('Invalid time value');
    }

    var localizeOptions = cloneObject(options);
    localizeOptions.addSuffix = Boolean(options.addSuffix);
    localizeOptions.comparison = comparison;
    var dateLeft;
    var dateRight;

    if (comparison > 0) {
      dateLeft = toDate(dirtyBaseDate);
      dateRight = toDate(dirtyDate);
    } else {
      dateLeft = toDate(dirtyDate);
      dateRight = toDate(dirtyBaseDate);
    }

    var seconds = differenceInSeconds(dateRight, dateLeft);
    var offsetInSeconds = (getTimezoneOffsetInMilliseconds(dateRight) - getTimezoneOffsetInMilliseconds(dateLeft)) / 1000;
    var minutes = Math.round((seconds - offsetInSeconds) / 60);
    var months; 

    if (minutes < 2) {
      if (options.includeSeconds) {
        if (seconds < 5) {
          return locale$1.formatDistance('lessThanXSeconds', 5, localizeOptions);
        } else if (seconds < 10) {
          return locale$1.formatDistance('lessThanXSeconds', 10, localizeOptions);
        } else if (seconds < 20) {
          return locale$1.formatDistance('lessThanXSeconds', 20, localizeOptions);
        } else if (seconds < 40) {
          return locale$1.formatDistance('halfAMinute', null, localizeOptions);
        } else if (seconds < 60) {
          return locale$1.formatDistance('lessThanXMinutes', 1, localizeOptions);
        } else {
          return locale$1.formatDistance('xMinutes', 1, localizeOptions);
        }
      } else {
        if (minutes === 0) {
          return locale$1.formatDistance('lessThanXMinutes', 1, localizeOptions);
        } else {
          return locale$1.formatDistance('xMinutes', minutes, localizeOptions);
        }
      } 

    } else if (minutes < 45) {
      return locale$1.formatDistance('xMinutes', minutes, localizeOptions); 
    } else if (minutes < 90) {
      return locale$1.formatDistance('aboutXHours', 1, localizeOptions); 
    } else if (minutes < MINUTES_IN_DAY$1) {
      var hours = Math.round(minutes / 60);
      return locale$1.formatDistance('aboutXHours', hours, localizeOptions); 
    } else if (minutes < MINUTES_IN_ALMOST_TWO_DAYS) {
      return locale$1.formatDistance('xDays', 1, localizeOptions); 
    } else if (minutes < MINUTES_IN_MONTH$1) {
      var days = Math.round(minutes / MINUTES_IN_DAY$1);
      return locale$1.formatDistance('xDays', days, localizeOptions); 
    } else if (minutes < MINUTES_IN_TWO_MONTHS) {
      months = Math.round(minutes / MINUTES_IN_MONTH$1);
      return locale$1.formatDistance('aboutXMonths', months, localizeOptions);
    }

    months = differenceInMonths(dateRight, dateLeft); 

    if (months < 12) {
      var nearestMonth = Math.round(minutes / MINUTES_IN_MONTH$1);
      return locale$1.formatDistance('xMonths', nearestMonth, localizeOptions); 
    } else {
      var monthsSinceStartOfYear = months % 12;
      var years = Math.floor(months / 12); 

      if (monthsSinceStartOfYear < 3) {
        return locale$1.formatDistance('aboutXYears', years, localizeOptions); 
      } else if (monthsSinceStartOfYear < 9) {
        return locale$1.formatDistance('overXYears', years, localizeOptions); 
      } else {
        return locale$1.formatDistance('almostXYears', years + 1, localizeOptions);
      }
    }
  }

  var MINUTES_IN_DAY = 1440;
  var MINUTES_IN_MONTH = 43200;
  var MINUTES_IN_YEAR = 525600;

  function formatDistanceStrict(dirtyDate, dirtyBaseDate, dirtyOptions) {
    requiredArgs(2, arguments);
    var options = dirtyOptions || {};
    var locale$1 = options.locale || locale;

    if (!locale$1.formatDistance) {
      throw new RangeError('locale must contain localize.formatDistance property');
    }

    var comparison = compareAsc(dirtyDate, dirtyBaseDate);

    if (isNaN(comparison)) {
      throw new RangeError('Invalid time value');
    }

    var localizeOptions = cloneObject(options);
    localizeOptions.addSuffix = Boolean(options.addSuffix);
    localizeOptions.comparison = comparison;
    var dateLeft;
    var dateRight;

    if (comparison > 0) {
      dateLeft = toDate(dirtyBaseDate);
      dateRight = toDate(dirtyDate);
    } else {
      dateLeft = toDate(dirtyDate);
      dateRight = toDate(dirtyBaseDate);
    }

    var roundingMethod = options.roundingMethod == null ? 'round' : String(options.roundingMethod);
    var roundingMethodFn;

    if (roundingMethod === 'floor') {
      roundingMethodFn = Math.floor;
    } else if (roundingMethod === 'ceil') {
      roundingMethodFn = Math.ceil;
    } else if (roundingMethod === 'round') {
      roundingMethodFn = Math.round;
    } else {
      throw new RangeError("roundingMethod must be 'floor', 'ceil' or 'round'");
    }

    var seconds = differenceInSeconds(dateRight, dateLeft);
    var offsetInSeconds = (getTimezoneOffsetInMilliseconds(dateRight) - getTimezoneOffsetInMilliseconds(dateLeft)) / 1000;
    var minutes = roundingMethodFn((seconds - offsetInSeconds) / 60);
    var unit;

    if (options.unit == null) {
      if (minutes < 1) {
        unit = 'second';
      } else if (minutes < 60) {
        unit = 'minute';
      } else if (minutes < MINUTES_IN_DAY) {
        unit = 'hour';
      } else if (minutes < MINUTES_IN_MONTH) {
        unit = 'day';
      } else if (minutes < MINUTES_IN_YEAR) {
        unit = 'month';
      } else {
        unit = 'year';
      }
    } else {
      unit = String(options.unit);
    } 


    if (unit === 'second') {
      return locale$1.formatDistance('xSeconds', seconds, localizeOptions); 
    } else if (unit === 'minute') {
      return locale$1.formatDistance('xMinutes', minutes, localizeOptions); 
    } else if (unit === 'hour') {
      var hours = roundingMethodFn(minutes / 60);
      return locale$1.formatDistance('xHours', hours, localizeOptions); 
    } else if (unit === 'day') {
      var days = roundingMethodFn(minutes / MINUTES_IN_DAY);
      return locale$1.formatDistance('xDays', days, localizeOptions); 
    } else if (unit === 'month') {
      var months = roundingMethodFn(minutes / MINUTES_IN_MONTH);
      return locale$1.formatDistance('xMonths', months, localizeOptions); 
    } else if (unit === 'year') {
      var years = roundingMethodFn(minutes / MINUTES_IN_YEAR);
      return locale$1.formatDistance('xYears', years, localizeOptions);
    }

    throw new RangeError("unit must be 'second', 'minute', 'hour', 'day', 'month' or 'year'");
  }


  function formatDistanceToNow(dirtyDate, dirtyOptions) {
    requiredArgs(1, arguments);
    return formatDistance(dirtyDate, Date.now(), dirtyOptions);
  }


  function formatDistanceToNowStrict(dirtyDate, dirtyOptions) {
    requiredArgs(1, arguments);
    return formatDistanceStrict(dirtyDate, Date.now(), dirtyOptions);
  }

  var defaultFormat = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];

  function formatDuration(duration) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (arguments.length < 1) {
      throw new TypeError("1 argument required, but only ".concat(arguments.length, " present"));
    }

    var format = options.format || defaultFormat;
    var locale$1 = options.locale || locale;
    var zero = options.zero || false;
    var delimiter = options.delimiter || ' ';
    var result = format.reduce(function (acc, unit) {
      var token = "x".concat(unit.replace(/(^.)/, function (m) {
        return m.toUpperCase();
      }));
      var addChunk = typeof duration[unit] === 'number' && (zero || duration[unit]);
      return addChunk ? acc.concat(locale$1.formatDistance(token, duration[unit])) : acc;
    }, []).join(delimiter);
    return result;
  }


  function formatISO(dirtyDate, dirtyOptions) {
    if (arguments.length < 1) {
      throw new TypeError("1 argument required, but only ".concat(arguments.length, " present"));
    }

    var originalDate = toDate(dirtyDate);

    if (!isValid(originalDate)) {
      throw new RangeError('Invalid time value');
    }

    var options = dirtyOptions || {};
    var format = options.format == null ? 'extended' : String(options.format);
    var representation = options.representation == null ? 'complete' : String(options.representation);

    if (format !== 'extended' && format !== 'basic') {
      throw new RangeError("format must be 'extended' or 'basic'");
    }

    if (representation !== 'date' && representation !== 'time' && representation !== 'complete') {
      throw new RangeError("representation must be 'date', 'time', or 'complete'");
    }

    var result = '';
    var tzOffset = '';
    var dateDelimiter = format === 'extended' ? '-' : '';
    var timeDelimiter = format === 'extended' ? ':' : ''; 

    if (representation !== 'time') {
      var day = addLeadingZeros(originalDate.getDate(), 2);
      var month = addLeadingZeros(originalDate.getMonth() + 1, 2);
      var year = addLeadingZeros(originalDate.getFullYear(), 4); 

      result = "".concat(year).concat(dateDelimiter).concat(month).concat(dateDelimiter).concat(day);
    } 


    if (representation !== 'date') {
      var offset = originalDate.getTimezoneOffset();

      if (offset !== 0) {
        var absoluteOffset = Math.abs(offset);
        var hourOffset = addLeadingZeros(Math.floor(absoluteOffset / 60), 2);
        var minuteOffset = addLeadingZeros(absoluteOffset % 60, 2); 

        var sign = offset < 0 ? '+' : '-';
        tzOffset = "".concat(sign).concat(hourOffset, ":").concat(minuteOffset);
      } else {
        tzOffset = 'Z';
      }

      var hour = addLeadingZeros(originalDate.getHours(), 2);
      var minute = addLeadingZeros(originalDate.getMinutes(), 2);
      var second = addLeadingZeros(originalDate.getSeconds(), 2); 

      var separator = result === '' ? '' : 'T'; 

      var time = [hour, minute, second].join(timeDelimiter); 

      result = "".concat(result).concat(separator).concat(time).concat(tzOffset);
    }

    return result;
  }


  function formatISO9075(dirtyDate, dirtyOptions) {
    if (arguments.length < 1) {
      throw new TypeError("1 argument required, but only ".concat(arguments.length, " present"));
    }

    var originalDate = toDate(dirtyDate);

    if (!isValid(originalDate)) {
      throw new RangeError('Invalid time value');
    }

    var options = dirtyOptions || {};
    var format = options.format == null ? 'extended' : String(options.format);
    var representation = options.representation == null ? 'complete' : String(options.representation);

    if (format !== 'extended' && format !== 'basic') {
      throw new RangeError("format must be 'extended' or 'basic'");
    }

    if (representation !== 'date' && representation !== 'time' && representation !== 'complete') {
      throw new RangeError("representation must be 'date', 'time', or 'complete'");
    }

    var result = '';
    var dateDelimiter = format === 'extended' ? '-' : '';
    var timeDelimiter = format === 'extended' ? ':' : ''; 

    if (representation !== 'time') {
      var day = addLeadingZeros(originalDate.getDate(), 2);
      var month = addLeadingZeros(originalDate.getMonth() + 1, 2);
      var year = addLeadingZeros(originalDate.getFullYear(), 4); 

      result = "".concat(year).concat(dateDelimiter).concat(month).concat(dateDelimiter).concat(day);
    } 


    if (representation !== 'date') {
      var hour = addLeadingZeros(originalDate.getHours(), 2);
      var minute = addLeadingZeros(originalDate.getMinutes(), 2);
      var second = addLeadingZeros(originalDate.getSeconds(), 2); 

      var separator = result === '' ? '' : ' '; 

      result = "".concat(result).concat(separator).concat(hour).concat(timeDelimiter).concat(minute).concat(timeDelimiter).concat(second);
    }

    return result;
  }


  function formatISODuration(duration) {
    requiredArgs(1, arguments);
    if (typeof duration !== 'object') throw new Error('Duration must be an object');
    var _duration$years = duration.years,
        years = _duration$years === void 0 ? 0 : _duration$years,
        _duration$months = duration.months,
        months = _duration$months === void 0 ? 0 : _duration$months,
        _duration$days = duration.days,
        days = _duration$days === void 0 ? 0 : _duration$days,
        _duration$hours = duration.hours,
        hours = _duration$hours === void 0 ? 0 : _duration$hours,
        _duration$minutes = duration.minutes,
        minutes = _duration$minutes === void 0 ? 0 : _duration$minutes,
        _duration$seconds = duration.seconds,
        seconds = _duration$seconds === void 0 ? 0 : _duration$seconds;
    return "P".concat(years, "Y").concat(months, "M").concat(days, "DT").concat(hours, "H").concat(minutes, "M").concat(seconds, "S");
  }


  function formatRFC3339(dirtyDate, dirtyOptions) {
    if (arguments.length < 1) {
      throw new TypeError("1 arguments required, but only ".concat(arguments.length, " present"));
    }

    var originalDate = toDate(dirtyDate);

    if (!isValid(originalDate)) {
      throw new RangeError('Invalid time value');
    }

    var options = dirtyOptions || {};
    var fractionDigits = options.fractionDigits == null ? 0 : toInteger(options.fractionDigits); 

    if (!(fractionDigits >= 0 && fractionDigits <= 3)) {
      throw new RangeError('fractionDigits must be between 0 and 3 inclusively');
    }

    var day = addLeadingZeros(originalDate.getDate(), 2);
    var month = addLeadingZeros(originalDate.getMonth() + 1, 2);
    var year = originalDate.getFullYear();
    var hour = addLeadingZeros(originalDate.getHours(), 2);
    var minute = addLeadingZeros(originalDate.getMinutes(), 2);
    var second = addLeadingZeros(originalDate.getSeconds(), 2);
    var fractionalSecond = '';

    if (fractionDigits > 0) {
      var milliseconds = originalDate.getMilliseconds();
      var fractionalSeconds = Math.floor(milliseconds * Math.pow(10, fractionDigits - 3));
      fractionalSecond = '.' + addLeadingZeros(fractionalSeconds, fractionDigits);
    }

    var offset = '';
    var tzOffset = originalDate.getTimezoneOffset();

    if (tzOffset !== 0) {
      var absoluteOffset = Math.abs(tzOffset);
      var hourOffset = addLeadingZeros(toInteger(absoluteOffset / 60), 2);
      var minuteOffset = addLeadingZeros(absoluteOffset % 60, 2); 

      var sign = tzOffset < 0 ? '+' : '-';
      offset = "".concat(sign).concat(hourOffset, ":").concat(minuteOffset);
    } else {
      offset = 'Z';
    }

    return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hour, ":").concat(minute, ":").concat(second).concat(fractionalSecond).concat(offset);
  }

  var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  function formatRFC7231(dirtyDate) {
    if (arguments.length < 1) {
      throw new TypeError("1 arguments required, but only ".concat(arguments.length, " present"));
    }

    var originalDate = toDate(dirtyDate);

    if (!isValid(originalDate)) {
      throw new RangeError('Invalid time value');
    }

    var dayName = days[originalDate.getUTCDay()];
    var dayOfMonth = addLeadingZeros(originalDate.getUTCDate(), 2);
    var monthName = months[originalDate.getUTCMonth()];
    var year = originalDate.getUTCFullYear();
    var hour = addLeadingZeros(originalDate.getUTCHours(), 2);
    var minute = addLeadingZeros(originalDate.getUTCMinutes(), 2);
    var second = addLeadingZeros(originalDate.getUTCSeconds(), 2); 

    return "".concat(dayName, ", ").concat(dayOfMonth, " ").concat(monthName, " ").concat(year, " ").concat(hour, ":").concat(minute, ":").concat(second, " GMT");
  }


  function formatRelative(dirtyDate, dirtyBaseDate, dirtyOptions) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var baseDate = toDate(dirtyBaseDate);
    var options = dirtyOptions || {};
    var locale$1 = options.locale || locale;

    if (!locale$1.localize) {
      throw new RangeError('locale must contain localize property');
    }

    if (!locale$1.formatLong) {
      throw new RangeError('locale must contain formatLong property');
    }

    if (!locale$1.formatRelative) {
      throw new RangeError('locale must contain formatRelative property');
    }

    var diff = differenceInCalendarDays(date, baseDate);

    if (isNaN(diff)) {
      throw new RangeError('Invalid time value');
    }

    var token;

    if (diff < -6) {
      token = 'other';
    } else if (diff < -1) {
      token = 'lastWeek';
    } else if (diff < 0) {
      token = 'yesterday';
    } else if (diff < 1) {
      token = 'today';
    } else if (diff < 2) {
      token = 'tomorrow';
    } else if (diff < 7) {
      token = 'nextWeek';
    } else {
      token = 'other';
    }

    var utcDate = subMilliseconds(date, getTimezoneOffsetInMilliseconds(date));
    var utcBaseDate = subMilliseconds(baseDate, getTimezoneOffsetInMilliseconds(baseDate));
    var formatStr = locale$1.formatRelative(token, utcDate, utcBaseDate, options);
    return format(date, formatStr, options);
  }


  function fromUnixTime(dirtyUnixTime) {
    requiredArgs(1, arguments);
    var unixTime = toInteger(dirtyUnixTime);
    return toDate(unixTime * 1000);
  }


  function getDate(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var dayOfMonth = date.getDate();
    return dayOfMonth;
  }


  function getDay(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var day = date.getDay();
    return day;
  }


  function getDayOfYear(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var diff = differenceInCalendarDays(date, startOfYear(date));
    var dayOfYear = diff + 1;
    return dayOfYear;
  }


  function getDaysInMonth(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var year = date.getFullYear();
    var monthIndex = date.getMonth();
    var lastDayOfMonth = new Date(0);
    lastDayOfMonth.setFullYear(year, monthIndex + 1, 0);
    lastDayOfMonth.setHours(0, 0, 0, 0);
    return lastDayOfMonth.getDate();
  }


  function isLeapYear(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var year = date.getFullYear();
    return year % 400 === 0 || year % 4 === 0 && year % 100 !== 0;
  }


  function getDaysInYear(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);

    if (isNaN(date)) {
      return NaN;
    }

    return isLeapYear(date) ? 366 : 365;
  }


  function getDecade(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var year = date.getFullYear();
    var decade = Math.floor(year / 10) * 10;
    return decade;
  }


  function getHours(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var hours = date.getHours();
    return hours;
  }


  function getISODay(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var day = date.getDay();

    if (day === 0) {
      day = 7;
    }

    return day;
  }

  var MILLISECONDS_IN_WEEK$2 = 604800000;

  function getISOWeek(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var diff = startOfISOWeek(date).getTime() - startOfISOWeekYear(date).getTime(); 

    return Math.round(diff / MILLISECONDS_IN_WEEK$2) + 1;
  }

  var MILLISECONDS_IN_WEEK$1 = 604800000;

  function getISOWeeksInYear(dirtyDate) {
    requiredArgs(1, arguments);
    var thisYear = startOfISOWeekYear(dirtyDate);
    var nextYear = startOfISOWeekYear(addWeeks(thisYear, 60));
    var diff = nextYear.valueOf() - thisYear.valueOf(); 

    return Math.round(diff / MILLISECONDS_IN_WEEK$1);
  }


  function getMilliseconds(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var milliseconds = date.getMilliseconds();
    return milliseconds;
  }


  function getMinutes(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var minutes = date.getMinutes();
    return minutes;
  }


  function getMonth(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var month = date.getMonth();
    return month;
  }

  var MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

  function getOverlappingDaysInIntervals(dirtyIntervalLeft, dirtyIntervalRight) {
    requiredArgs(2, arguments);
    var intervalLeft = dirtyIntervalLeft || {};
    var intervalRight = dirtyIntervalRight || {};
    var leftStartTime = toDate(intervalLeft.start).getTime();
    var leftEndTime = toDate(intervalLeft.end).getTime();
    var rightStartTime = toDate(intervalRight.start).getTime();
    var rightEndTime = toDate(intervalRight.end).getTime(); 

    if (!(leftStartTime <= leftEndTime && rightStartTime <= rightEndTime)) {
      throw new RangeError('Invalid interval');
    }

    var isOverlapping = leftStartTime < rightEndTime && rightStartTime < leftEndTime;

    if (!isOverlapping) {
      return 0;
    }

    var overlapStartDate = rightStartTime < leftStartTime ? leftStartTime : rightStartTime;
    var overlapEndDate = rightEndTime > leftEndTime ? leftEndTime : rightEndTime;
    var differenceInMs = overlapEndDate - overlapStartDate;
    return Math.ceil(differenceInMs / MILLISECONDS_IN_DAY);
  }


  function getSeconds(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var seconds = date.getSeconds();
    return seconds;
  }


  function getTime(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var timestamp = date.getTime();
    return timestamp;
  }


  function getUnixTime(dirtyDate) {
    requiredArgs(1, arguments);
    return Math.floor(getTime(dirtyDate) / 1000);
  }


  function getWeekYear(dirtyDate, dirtyOptions) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var year = date.getFullYear();
    var options = dirtyOptions || {};
    var locale = options.locale;
    var localeFirstWeekContainsDate = locale && locale.options && locale.options.firstWeekContainsDate;
    var defaultFirstWeekContainsDate = localeFirstWeekContainsDate == null ? 1 : toInteger(localeFirstWeekContainsDate);
    var firstWeekContainsDate = options.firstWeekContainsDate == null ? defaultFirstWeekContainsDate : toInteger(options.firstWeekContainsDate); 

    if (!(firstWeekContainsDate >= 1 && firstWeekContainsDate <= 7)) {
      throw new RangeError('firstWeekContainsDate must be between 1 and 7 inclusively');
    }

    var firstWeekOfNextYear = new Date(0);
    firstWeekOfNextYear.setFullYear(year + 1, 0, firstWeekContainsDate);
    firstWeekOfNextYear.setHours(0, 0, 0, 0);
    var startOfNextYear = startOfWeek(firstWeekOfNextYear, dirtyOptions);
    var firstWeekOfThisYear = new Date(0);
    firstWeekOfThisYear.setFullYear(year, 0, firstWeekContainsDate);
    firstWeekOfThisYear.setHours(0, 0, 0, 0);
    var startOfThisYear = startOfWeek(firstWeekOfThisYear, dirtyOptions);

    if (date.getTime() >= startOfNextYear.getTime()) {
      return year + 1;
    } else if (date.getTime() >= startOfThisYear.getTime()) {
      return year;
    } else {
      return year - 1;
    }
  }


  function startOfWeekYear(dirtyDate, dirtyOptions) {
    requiredArgs(1, arguments);
    var options = dirtyOptions || {};
    var locale = options.locale;
    var localeFirstWeekContainsDate = locale && locale.options && locale.options.firstWeekContainsDate;
    var defaultFirstWeekContainsDate = localeFirstWeekContainsDate == null ? 1 : toInteger(localeFirstWeekContainsDate);
    var firstWeekContainsDate = options.firstWeekContainsDate == null ? defaultFirstWeekContainsDate : toInteger(options.firstWeekContainsDate);
    var year = getWeekYear(dirtyDate, dirtyOptions);
    var firstWeek = new Date(0);
    firstWeek.setFullYear(year, 0, firstWeekContainsDate);
    firstWeek.setHours(0, 0, 0, 0);
    var date = startOfWeek(firstWeek, dirtyOptions);
    return date;
  }

  var MILLISECONDS_IN_WEEK = 604800000;

  function getWeek(dirtyDate, options) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var diff = startOfWeek(date, options).getTime() - startOfWeekYear(date, options).getTime(); 

    return Math.round(diff / MILLISECONDS_IN_WEEK) + 1;
  }


  function getWeekOfMonth(date, dirtyOptions) {
    requiredArgs(1, arguments);
    var options = dirtyOptions || {};
    var locale = options.locale;
    var localeWeekStartsOn = locale && locale.options && locale.options.weekStartsOn;
    var defaultWeekStartsOn = localeWeekStartsOn == null ? 0 : toInteger(localeWeekStartsOn);
    var weekStartsOn = options.weekStartsOn == null ? defaultWeekStartsOn : toInteger(options.weekStartsOn); 

    if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
      throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
    }

    var currentDayOfMonth = getDate(date);

    if (isNaN(currentDayOfMonth)) {
      return currentDayOfMonth;
    }

    var startWeekDay = getDay(startOfMonth(date));
    var lastDayOfFirstWeek = 0;

    if (startWeekDay >= weekStartsOn) {
      lastDayOfFirstWeek = weekStartsOn + 7 - startWeekDay;
    } else {
      lastDayOfFirstWeek = weekStartsOn - startWeekDay;
    }

    var weekNumber = 1;

    if (currentDayOfMonth > lastDayOfFirstWeek) {
      var remainingDaysAfterFirstWeek = currentDayOfMonth - lastDayOfFirstWeek;
      weekNumber = weekNumber + Math.ceil(remainingDaysAfterFirstWeek / 7);
    }

    return weekNumber;
  }


  function lastDayOfMonth(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var month = date.getMonth();
    date.setFullYear(date.getFullYear(), month + 1, 0);
    date.setHours(0, 0, 0, 0);
    return date;
  }


  function getWeeksInMonth(date, options) {
    requiredArgs(1, arguments);
    return differenceInCalendarWeeks(lastDayOfMonth(date), startOfMonth(date), options) + 1;
  }


  function getYear(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var year = date.getFullYear();
    return year;
  }


  function subDays(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addDays(dirtyDate, -amount);
  }


  function subMonths(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addMonths(dirtyDate, -amount);
  }


  function sub(dirtyDate, duration) {
    requiredArgs(2, arguments);
    if (!duration || typeof duration !== 'object') return new Date(NaN);
    var years = 'years' in duration ? toInteger(duration.years) : 0;
    var months = 'months' in duration ? toInteger(duration.months) : 0;
    var weeks = 'weeks' in duration ? toInteger(duration.weeks) : 0;
    var days = 'days' in duration ? toInteger(duration.days) : 0;
    var hours = 'hours' in duration ? toInteger(duration.hours) : 0;
    var minutes = 'minutes' in duration ? toInteger(duration.minutes) : 0;
    var seconds = 'seconds' in duration ? toInteger(duration.seconds) : 0; 

    var dateWithoutMonths = subMonths(toDate(dirtyDate), months + years * 12); 

    var dateWithoutDays = subDays(dateWithoutMonths, days + weeks * 7); 

    var minutestoSub = minutes + hours * 60;
    var secondstoSub = seconds + minutestoSub * 60;
    var mstoSub = secondstoSub * 1000;
    var finalDate = new Date(dateWithoutDays.getTime() - mstoSub);
    return finalDate;
  }


  function intervalToDuration(_ref) {
    var start = _ref.start,
        end = _ref.end;
    requiredArgs(1, arguments);
    var dateLeft = toDate(start);
    var dateRight = toDate(end);

    if (!isValid(dateLeft)) {
      throw new RangeError('Start Date is invalid');
    }

    if (!isValid(dateRight)) {
      throw new RangeError('End Date is invalid');
    }

    var duration = {
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
    var sign = compareAsc(dateLeft, dateRight);
    duration.years = Math.abs(differenceInYears(dateLeft, dateRight));
    var remainingMonths = sub(dateLeft, {
      years: sign * duration.years
    });
    duration.months = Math.abs(differenceInMonths(remainingMonths, dateRight));
    var remainingDays = sub(remainingMonths, {
      months: sign * duration.months
    });
    duration.days = Math.abs(differenceInDays(remainingDays, dateRight));
    var remainingHours = sub(remainingDays, {
      days: sign * duration.days
    });
    duration.hours = Math.abs(differenceInHours(remainingHours, dateRight));
    var remainingMinutes = sub(remainingHours, {
      hours: sign * duration.hours
    });
    duration.minutes = Math.abs(differenceInMinutes(remainingMinutes, dateRight));
    var remainingSeconds = sub(remainingMinutes, {
      minutes: sign * duration.minutes
    });
    duration.seconds = Math.abs(differenceInSeconds(remainingSeconds, dateRight));
    return duration;
  }


  function isAfter(dirtyDate, dirtyDateToCompare) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var dateToCompare = toDate(dirtyDateToCompare);
    return date.getTime() > dateToCompare.getTime();
  }


  function isBefore(dirtyDate, dirtyDateToCompare) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var dateToCompare = toDate(dirtyDateToCompare);
    return date.getTime() < dateToCompare.getTime();
  }


  function isDate(value) {
    requiredArgs(1, arguments);
    return value instanceof Date || typeof value === 'object' && Object.prototype.toString.call(value) === '[object Date]';
  }


  function isEqual(dirtyLeftDate, dirtyRightDate) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyLeftDate);
    var dateRight = toDate(dirtyRightDate);
    return dateLeft.getTime() === dateRight.getTime();
  }

  function isExists(year, month, day) {
    if (arguments.length < 3) {
      throw new TypeError('3 argument required, but only ' + arguments.length + ' present');
    }

    var date = new Date(year, month, day);
    return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
  }


  function isFirstDayOfMonth(dirtyDate) {
    requiredArgs(1, arguments);
    return toDate(dirtyDate).getDate() === 1;
  }


  function isFriday(dirtyDate) {
    requiredArgs(1, arguments);
    return toDate(dirtyDate).getDay() === 5;
  }


  function isFuture(dirtyDate) {
    requiredArgs(1, arguments);
    return toDate(dirtyDate).getTime() > Date.now();
  }


  function isLastDayOfMonth(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    return endOfDay(date).getTime() === endOfMonth(date).getTime();
  }


  function setUTCDay(dirtyDate, dirtyDay, dirtyOptions) {
    requiredArgs(2, arguments);
    var options = dirtyOptions || {};
    var locale = options.locale;
    var localeWeekStartsOn = locale && locale.options && locale.options.weekStartsOn;
    var defaultWeekStartsOn = localeWeekStartsOn == null ? 0 : toInteger(localeWeekStartsOn);
    var weekStartsOn = options.weekStartsOn == null ? defaultWeekStartsOn : toInteger(options.weekStartsOn); 

    if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
      throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
    }

    var date = toDate(dirtyDate);
    var day = toInteger(dirtyDay);
    var currentDay = date.getUTCDay();
    var remainder = day % 7;
    var dayIndex = (remainder + 7) % 7;
    var diff = (dayIndex < weekStartsOn ? 7 : 0) + day - currentDay;
    date.setUTCDate(date.getUTCDate() + diff);
    return date;
  }


  function setUTCISODay(dirtyDate, dirtyDay) {
    requiredArgs(2, arguments);
    var day = toInteger(dirtyDay);

    if (day % 7 === 0) {
      day = day - 7;
    }

    var weekStartsOn = 1;
    var date = toDate(dirtyDate);
    var currentDay = date.getUTCDay();
    var remainder = day % 7;
    var dayIndex = (remainder + 7) % 7;
    var diff = (dayIndex < weekStartsOn ? 7 : 0) + day - currentDay;
    date.setUTCDate(date.getUTCDate() + diff);
    return date;
  }


  function setUTCISOWeek(dirtyDate, dirtyISOWeek) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var isoWeek = toInteger(dirtyISOWeek);
    var diff = getUTCISOWeek(date) - isoWeek;
    date.setUTCDate(date.getUTCDate() - diff * 7);
    return date;
  }


  function setUTCWeek(dirtyDate, dirtyWeek, options) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var week = toInteger(dirtyWeek);
    var diff = getUTCWeek(date, options) - week;
    date.setUTCDate(date.getUTCDate() - diff * 7);
    return date;
  }

  var MILLISECONDS_IN_HOUR$1 = 3600000;
  var MILLISECONDS_IN_MINUTE$1 = 60000;
  var MILLISECONDS_IN_SECOND = 1000;
  var numericPatterns = {
    month: /^(1[0-2]|0?\d)/,
    date: /^(3[0-1]|[0-2]?\d)/,
    dayOfYear: /^(36[0-6]|3[0-5]\d|[0-2]?\d?\d)/,
    week: /^(5[0-3]|[0-4]?\d)/,
    hour23h: /^(2[0-3]|[0-1]?\d)/,
    hour24h: /^(2[0-4]|[0-1]?\d)/,
    hour11h: /^(1[0-1]|0?\d)/,
    hour12h: /^(1[0-2]|0?\d)/,
    minute: /^[0-5]?\d/,
    second: /^[0-5]?\d/,
    singleDigit: /^\d/,
    twoDigits: /^\d{1,2}/,
    threeDigits: /^\d{1,3}/,
    fourDigits: /^\d{1,4}/,
    anyDigitsSigned: /^-?\d+/,
    singleDigitSigned: /^-?\d/,
    twoDigitsSigned: /^-?\d{1,2}/,
    threeDigitsSigned: /^-?\d{1,3}/,
    fourDigitsSigned: /^-?\d{1,4}/ 

  };
  var timezonePatterns = {
    basicOptionalMinutes: /^([+-])(\d{2})(\d{2})?|Z/,
    basic: /^([+-])(\d{2})(\d{2})|Z/,
    basicOptionalSeconds: /^([+-])(\d{2})(\d{2})((\d{2}))?|Z/,
    extended: /^([+-])(\d{2}):(\d{2})|Z/,
    extendedOptionalSeconds: /^([+-])(\d{2}):(\d{2})(:(\d{2}))?|Z/
  };

  function parseNumericPattern(pattern, string, valueCallback) {
    var matchResult = string.match(pattern);

    if (!matchResult) {
      return null;
    }

    var value = parseInt(matchResult[0], 10);
    return {
      value: valueCallback ? valueCallback(value) : value,
      rest: string.slice(matchResult[0].length)
    };
  }

  function parseTimezonePattern(pattern, string) {
    var matchResult = string.match(pattern);

    if (!matchResult) {
      return null;
    } 


    if (matchResult[0] === 'Z') {
      return {
        value: 0,
        rest: string.slice(1)
      };
    }

    var sign = matchResult[1] === '+' ? 1 : -1;
    var hours = matchResult[2] ? parseInt(matchResult[2], 10) : 0;
    var minutes = matchResult[3] ? parseInt(matchResult[3], 10) : 0;
    var seconds = matchResult[5] ? parseInt(matchResult[5], 10) : 0;
    return {
      value: sign * (hours * MILLISECONDS_IN_HOUR$1 + minutes * MILLISECONDS_IN_MINUTE$1 + seconds * MILLISECONDS_IN_SECOND),
      rest: string.slice(matchResult[0].length)
    };
  }

  function parseAnyDigitsSigned(string, valueCallback) {
    return parseNumericPattern(numericPatterns.anyDigitsSigned, string, valueCallback);
  }

  function parseNDigits(n, string, valueCallback) {
    switch (n) {
      case 1:
        return parseNumericPattern(numericPatterns.singleDigit, string, valueCallback);

      case 2:
        return parseNumericPattern(numericPatterns.twoDigits, string, valueCallback);

      case 3:
        return parseNumericPattern(numericPatterns.threeDigits, string, valueCallback);

      case 4:
        return parseNumericPattern(numericPatterns.fourDigits, string, valueCallback);

      default:
        return parseNumericPattern(new RegExp('^\\d{1,' + n + '}'), string, valueCallback);
    }
  }

  function parseNDigitsSigned(n, string, valueCallback) {
    switch (n) {
      case 1:
        return parseNumericPattern(numericPatterns.singleDigitSigned, string, valueCallback);

      case 2:
        return parseNumericPattern(numericPatterns.twoDigitsSigned, string, valueCallback);

      case 3:
        return parseNumericPattern(numericPatterns.threeDigitsSigned, string, valueCallback);

      case 4:
        return parseNumericPattern(numericPatterns.fourDigitsSigned, string, valueCallback);

      default:
        return parseNumericPattern(new RegExp('^-?\\d{1,' + n + '}'), string, valueCallback);
    }
  }

  function dayPeriodEnumToHours(enumValue) {
    switch (enumValue) {
      case 'morning':
        return 4;

      case 'evening':
        return 17;

      case 'pm':
      case 'noon':
      case 'afternoon':
        return 12;

      case 'am':
      case 'midnight':
      case 'night':
      default:
        return 0;
    }
  }

  function normalizeTwoDigitYear(twoDigitYear, currentYear) {
    var isCommonEra = currentYear > 0; 

    var absCurrentYear = isCommonEra ? currentYear : 1 - currentYear;
    var result;

    if (absCurrentYear <= 50) {
      result = twoDigitYear || 100;
    } else {
      var rangeEnd = absCurrentYear + 50;
      var rangeEndCentury = Math.floor(rangeEnd / 100) * 100;
      var isPreviousCentury = twoDigitYear >= rangeEnd % 100;
      result = twoDigitYear + rangeEndCentury - (isPreviousCentury ? 100 : 0);
    }

    return isCommonEra ? result : 1 - result;
  }

  var DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  var DAYS_IN_MONTH_LEAP_YEAR = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; 

  function isLeapYearIndex$1(year) {
    return year % 400 === 0 || year % 4 === 0 && year % 100 !== 0;
  }


  var parsers = {
    G: {
      priority: 140,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'G':
          case 'GG':
          case 'GGG':
            return match.era(string, {
              width: 'abbreviated'
            }) || match.era(string, {
              width: 'narrow'
            });

          case 'GGGGG':
            return match.era(string, {
              width: 'narrow'
            });

          case 'GGGG':
          default:
            return match.era(string, {
              width: 'wide'
            }) || match.era(string, {
              width: 'abbreviated'
            }) || match.era(string, {
              width: 'narrow'
            });
        }
      },
      set: function (date, flags, value, _options) {
        flags.era = value;
        date.setUTCFullYear(value, 0, 1);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['R', 'u', 't', 'T']
    },
    y: {
      priority: 130,
      parse: function (string, token, match, _options) {
        var valueCallback = function (year) {
          return {
            year: year,
            isTwoDigitYear: token === 'yy'
          };
        };

        switch (token) {
          case 'y':
            return parseNDigits(4, string, valueCallback);

          case 'yo':
            return match.ordinalNumber(string, {
              unit: 'year',
              valueCallback: valueCallback
            });

          default:
            return parseNDigits(token.length, string, valueCallback);
        }
      },
      validate: function (_date, value, _options) {
        return value.isTwoDigitYear || value.year > 0;
      },
      set: function (date, flags, value, _options) {
        var currentYear = date.getUTCFullYear();

        if (value.isTwoDigitYear) {
          var normalizedTwoDigitYear = normalizeTwoDigitYear(value.year, currentYear);
          date.setUTCFullYear(normalizedTwoDigitYear, 0, 1);
          date.setUTCHours(0, 0, 0, 0);
          return date;
        }

        var year = !('era' in flags) || flags.era === 1 ? value.year : 1 - value.year;
        date.setUTCFullYear(year, 0, 1);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['Y', 'R', 'u', 'w', 'I', 'i', 'e', 'c', 't', 'T']
    },
    Y: {
      priority: 130,
      parse: function (string, token, match, _options) {
        var valueCallback = function (year) {
          return {
            year: year,
            isTwoDigitYear: token === 'YY'
          };
        };

        switch (token) {
          case 'Y':
            return parseNDigits(4, string, valueCallback);

          case 'Yo':
            return match.ordinalNumber(string, {
              unit: 'year',
              valueCallback: valueCallback
            });

          default:
            return parseNDigits(token.length, string, valueCallback);
        }
      },
      validate: function (_date, value, _options) {
        return value.isTwoDigitYear || value.year > 0;
      },
      set: function (date, flags, value, options) {
        var currentYear = getUTCWeekYear(date, options);

        if (value.isTwoDigitYear) {
          var normalizedTwoDigitYear = normalizeTwoDigitYear(value.year, currentYear);
          date.setUTCFullYear(normalizedTwoDigitYear, 0, options.firstWeekContainsDate);
          date.setUTCHours(0, 0, 0, 0);
          return startOfUTCWeek(date, options);
        }

        var year = !('era' in flags) || flags.era === 1 ? value.year : 1 - value.year;
        date.setUTCFullYear(year, 0, options.firstWeekContainsDate);
        date.setUTCHours(0, 0, 0, 0);
        return startOfUTCWeek(date, options);
      },
      incompatibleTokens: ['y', 'R', 'u', 'Q', 'q', 'M', 'L', 'I', 'd', 'D', 'i', 't', 'T']
    },
    R: {
      priority: 130,
      parse: function (string, token, _match, _options) {
        if (token === 'R') {
          return parseNDigitsSigned(4, string);
        }

        return parseNDigitsSigned(token.length, string);
      },
      set: function (_date, _flags, value, _options) {
        var firstWeekOfYear = new Date(0);
        firstWeekOfYear.setUTCFullYear(value, 0, 4);
        firstWeekOfYear.setUTCHours(0, 0, 0, 0);
        return startOfUTCISOWeek(firstWeekOfYear);
      },
      incompatibleTokens: ['G', 'y', 'Y', 'u', 'Q', 'q', 'M', 'L', 'w', 'd', 'D', 'e', 'c', 't', 'T']
    },
    u: {
      priority: 130,
      parse: function (string, token, _match, _options) {
        if (token === 'u') {
          return parseNDigitsSigned(4, string);
        }

        return parseNDigitsSigned(token.length, string);
      },
      set: function (date, _flags, value, _options) {
        date.setUTCFullYear(value, 0, 1);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['G', 'y', 'Y', 'R', 'w', 'I', 'i', 'e', 'c', 't', 'T']
    },
    Q: {
      priority: 120,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'Q':
          case 'QQ':
            return parseNDigits(token.length, string);

          case 'Qo':
            return match.ordinalNumber(string, {
              unit: 'quarter'
            });

          case 'QQQ':
            return match.quarter(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.quarter(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'QQQQQ':
            return match.quarter(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'QQQQ':
          default:
            return match.quarter(string, {
              width: 'wide',
              context: 'formatting'
            }) || match.quarter(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.quarter(string, {
              width: 'narrow',
              context: 'formatting'
            });
        }
      },
      validate: function (_date, value, _options) {
        return value >= 1 && value <= 4;
      },
      set: function (date, _flags, value, _options) {
        date.setUTCMonth((value - 1) * 3, 1);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['Y', 'R', 'q', 'M', 'L', 'w', 'I', 'd', 'D', 'i', 'e', 'c', 't', 'T']
    },
    q: {
      priority: 120,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'q':
          case 'qq':
            return parseNDigits(token.length, string);

          case 'qo':
            return match.ordinalNumber(string, {
              unit: 'quarter'
            });

          case 'qqq':
            return match.quarter(string, {
              width: 'abbreviated',
              context: 'standalone'
            }) || match.quarter(string, {
              width: 'narrow',
              context: 'standalone'
            });

          case 'qqqqq':
            return match.quarter(string, {
              width: 'narrow',
              context: 'standalone'
            });

          case 'qqqq':
          default:
            return match.quarter(string, {
              width: 'wide',
              context: 'standalone'
            }) || match.quarter(string, {
              width: 'abbreviated',
              context: 'standalone'
            }) || match.quarter(string, {
              width: 'narrow',
              context: 'standalone'
            });
        }
      },
      validate: function (_date, value, _options) {
        return value >= 1 && value <= 4;
      },
      set: function (date, _flags, value, _options) {
        date.setUTCMonth((value - 1) * 3, 1);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['Y', 'R', 'Q', 'M', 'L', 'w', 'I', 'd', 'D', 'i', 'e', 'c', 't', 'T']
    },
    M: {
      priority: 110,
      parse: function (string, token, match, _options) {
        var valueCallback = function (value) {
          return value - 1;
        };

        switch (token) {
          case 'M':
            return parseNumericPattern(numericPatterns.month, string, valueCallback);

          case 'MM':
            return parseNDigits(2, string, valueCallback);

          case 'Mo':
            return match.ordinalNumber(string, {
              unit: 'month',
              valueCallback: valueCallback
            });

          case 'MMM':
            return match.month(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.month(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'MMMMM':
            return match.month(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'MMMM':
          default:
            return match.month(string, {
              width: 'wide',
              context: 'formatting'
            }) || match.month(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.month(string, {
              width: 'narrow',
              context: 'formatting'
            });
        }
      },
      validate: function (_date, value, _options) {
        return value >= 0 && value <= 11;
      },
      set: function (date, _flags, value, _options) {
        date.setUTCMonth(value, 1);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['Y', 'R', 'q', 'Q', 'L', 'w', 'I', 'D', 'i', 'e', 'c', 't', 'T']
    },
    L: {
      priority: 110,
      parse: function (string, token, match, _options) {
        var valueCallback = function (value) {
          return value - 1;
        };

        switch (token) {
          case 'L':
            return parseNumericPattern(numericPatterns.month, string, valueCallback);

          case 'LL':
            return parseNDigits(2, string, valueCallback);

          case 'Lo':
            return match.ordinalNumber(string, {
              unit: 'month',
              valueCallback: valueCallback
            });

          case 'LLL':
            return match.month(string, {
              width: 'abbreviated',
              context: 'standalone'
            }) || match.month(string, {
              width: 'narrow',
              context: 'standalone'
            });

          case 'LLLLL':
            return match.month(string, {
              width: 'narrow',
              context: 'standalone'
            });

          case 'LLLL':
          default:
            return match.month(string, {
              width: 'wide',
              context: 'standalone'
            }) || match.month(string, {
              width: 'abbreviated',
              context: 'standalone'
            }) || match.month(string, {
              width: 'narrow',
              context: 'standalone'
            });
        }
      },
      validate: function (_date, value, _options) {
        return value >= 0 && value <= 11;
      },
      set: function (date, _flags, value, _options) {
        date.setUTCMonth(value, 1);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['Y', 'R', 'q', 'Q', 'M', 'w', 'I', 'D', 'i', 'e', 'c', 't', 'T']
    },
    w: {
      priority: 100,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'w':
            return parseNumericPattern(numericPatterns.week, string);

          case 'wo':
            return match.ordinalNumber(string, {
              unit: 'week'
            });

          default:
            return parseNDigits(token.length, string);
        }
      },
      validate: function (_date, value, _options) {
        return value >= 1 && value <= 53;
      },
      set: function (date, _flags, value, options) {
        return startOfUTCWeek(setUTCWeek(date, value, options), options);
      },
      incompatibleTokens: ['y', 'R', 'u', 'q', 'Q', 'M', 'L', 'I', 'd', 'D', 'i', 't', 'T']
    },
    I: {
      priority: 100,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'I':
            return parseNumericPattern(numericPatterns.week, string);

          case 'Io':
            return match.ordinalNumber(string, {
              unit: 'week'
            });

          default:
            return parseNDigits(token.length, string);
        }
      },
      validate: function (_date, value, _options) {
        return value >= 1 && value <= 53;
      },
      set: function (date, _flags, value, options) {
        return startOfUTCISOWeek(setUTCISOWeek(date, value, options), options);
      },
      incompatibleTokens: ['y', 'Y', 'u', 'q', 'Q', 'M', 'L', 'w', 'd', 'D', 'e', 'c', 't', 'T']
    },
    d: {
      priority: 90,
      subPriority: 1,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'd':
            return parseNumericPattern(numericPatterns.date, string);

          case 'do':
            return match.ordinalNumber(string, {
              unit: 'date'
            });

          default:
            return parseNDigits(token.length, string);
        }
      },
      validate: function (date, value, _options) {
        var year = date.getUTCFullYear();
        var isLeapYear = isLeapYearIndex$1(year);
        var month = date.getUTCMonth();

        if (isLeapYear) {
          return value >= 1 && value <= DAYS_IN_MONTH_LEAP_YEAR[month];
        } else {
          return value >= 1 && value <= DAYS_IN_MONTH[month];
        }
      },
      set: function (date, _flags, value, _options) {
        date.setUTCDate(value);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['Y', 'R', 'q', 'Q', 'w', 'I', 'D', 'i', 'e', 'c', 't', 'T']
    },
    D: {
      priority: 90,
      subPriority: 1,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'D':
          case 'DD':
            return parseNumericPattern(numericPatterns.dayOfYear, string);

          case 'Do':
            return match.ordinalNumber(string, {
              unit: 'date'
            });

          default:
            return parseNDigits(token.length, string);
        }
      },
      validate: function (date, value, _options) {
        var year = date.getUTCFullYear();
        var isLeapYear = isLeapYearIndex$1(year);

        if (isLeapYear) {
          return value >= 1 && value <= 366;
        } else {
          return value >= 1 && value <= 365;
        }
      },
      set: function (date, _flags, value, _options) {
        date.setUTCMonth(0, value);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['Y', 'R', 'q', 'Q', 'M', 'L', 'w', 'I', 'd', 'E', 'i', 'e', 'c', 't', 'T']
    },
    E: {
      priority: 90,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'E':
          case 'EE':
          case 'EEE':
            return match.day(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.day(string, {
              width: 'short',
              context: 'formatting'
            }) || match.day(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'EEEEE':
            return match.day(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'EEEEEE':
            return match.day(string, {
              width: 'short',
              context: 'formatting'
            }) || match.day(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'EEEE':
          default:
            return match.day(string, {
              width: 'wide',
              context: 'formatting'
            }) || match.day(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.day(string, {
              width: 'short',
              context: 'formatting'
            }) || match.day(string, {
              width: 'narrow',
              context: 'formatting'
            });
        }
      },
      validate: function (_date, value, _options) {
        return value >= 0 && value <= 6;
      },
      set: function (date, _flags, value, options) {
        date = setUTCDay(date, value, options);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['D', 'i', 'e', 'c', 't', 'T']
    },
    e: {
      priority: 90,
      parse: function (string, token, match, options) {
        var valueCallback = function (value) {
          var wholeWeekDays = Math.floor((value - 1) / 7) * 7;
          return (value + options.weekStartsOn + 6) % 7 + wholeWeekDays;
        };

        switch (token) {
          case 'e':
          case 'ee':
            return parseNDigits(token.length, string, valueCallback);

          case 'eo':
            return match.ordinalNumber(string, {
              unit: 'day',
              valueCallback: valueCallback
            });

          case 'eee':
            return match.day(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.day(string, {
              width: 'short',
              context: 'formatting'
            }) || match.day(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'eeeee':
            return match.day(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'eeeeee':
            return match.day(string, {
              width: 'short',
              context: 'formatting'
            }) || match.day(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'eeee':
          default:
            return match.day(string, {
              width: 'wide',
              context: 'formatting'
            }) || match.day(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.day(string, {
              width: 'short',
              context: 'formatting'
            }) || match.day(string, {
              width: 'narrow',
              context: 'formatting'
            });
        }
      },
      validate: function (_date, value, _options) {
        return value >= 0 && value <= 6;
      },
      set: function (date, _flags, value, options) {
        date = setUTCDay(date, value, options);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['y', 'R', 'u', 'q', 'Q', 'M', 'L', 'I', 'd', 'D', 'E', 'i', 'c', 't', 'T']
    },
    c: {
      priority: 90,
      parse: function (string, token, match, options) {
        var valueCallback = function (value) {
          var wholeWeekDays = Math.floor((value - 1) / 7) * 7;
          return (value + options.weekStartsOn + 6) % 7 + wholeWeekDays;
        };

        switch (token) {
          case 'c':
          case 'cc':
            return parseNDigits(token.length, string, valueCallback);

          case 'co':
            return match.ordinalNumber(string, {
              unit: 'day',
              valueCallback: valueCallback
            });

          case 'ccc':
            return match.day(string, {
              width: 'abbreviated',
              context: 'standalone'
            }) || match.day(string, {
              width: 'short',
              context: 'standalone'
            }) || match.day(string, {
              width: 'narrow',
              context: 'standalone'
            });

          case 'ccccc':
            return match.day(string, {
              width: 'narrow',
              context: 'standalone'
            });

          case 'cccccc':
            return match.day(string, {
              width: 'short',
              context: 'standalone'
            }) || match.day(string, {
              width: 'narrow',
              context: 'standalone'
            });

          case 'cccc':
          default:
            return match.day(string, {
              width: 'wide',
              context: 'standalone'
            }) || match.day(string, {
              width: 'abbreviated',
              context: 'standalone'
            }) || match.day(string, {
              width: 'short',
              context: 'standalone'
            }) || match.day(string, {
              width: 'narrow',
              context: 'standalone'
            });
        }
      },
      validate: function (_date, value, _options) {
        return value >= 0 && value <= 6;
      },
      set: function (date, _flags, value, options) {
        date = setUTCDay(date, value, options);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['y', 'R', 'u', 'q', 'Q', 'M', 'L', 'I', 'd', 'D', 'E', 'i', 'e', 't', 'T']
    },
    i: {
      priority: 90,
      parse: function (string, token, match, _options) {
        var valueCallback = function (value) {
          if (value === 0) {
            return 7;
          }

          return value;
        };

        switch (token) {
          case 'i':
          case 'ii':
            return parseNDigits(token.length, string);

          case 'io':
            return match.ordinalNumber(string, {
              unit: 'day'
            });

          case 'iii':
            return match.day(string, {
              width: 'abbreviated',
              context: 'formatting',
              valueCallback: valueCallback
            }) || match.day(string, {
              width: 'short',
              context: 'formatting',
              valueCallback: valueCallback
            }) || match.day(string, {
              width: 'narrow',
              context: 'formatting',
              valueCallback: valueCallback
            });

          case 'iiiii':
            return match.day(string, {
              width: 'narrow',
              context: 'formatting',
              valueCallback: valueCallback
            });

          case 'iiiiii':
            return match.day(string, {
              width: 'short',
              context: 'formatting',
              valueCallback: valueCallback
            }) || match.day(string, {
              width: 'narrow',
              context: 'formatting',
              valueCallback: valueCallback
            });

          case 'iiii':
          default:
            return match.day(string, {
              width: 'wide',
              context: 'formatting',
              valueCallback: valueCallback
            }) || match.day(string, {
              width: 'abbreviated',
              context: 'formatting',
              valueCallback: valueCallback
            }) || match.day(string, {
              width: 'short',
              context: 'formatting',
              valueCallback: valueCallback
            }) || match.day(string, {
              width: 'narrow',
              context: 'formatting',
              valueCallback: valueCallback
            });
        }
      },
      validate: function (_date, value, _options) {
        return value >= 1 && value <= 7;
      },
      set: function (date, _flags, value, options) {
        date = setUTCISODay(date, value, options);
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['y', 'Y', 'u', 'q', 'Q', 'M', 'L', 'w', 'd', 'D', 'E', 'e', 'c', 't', 'T']
    },
    a: {
      priority: 80,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'a':
          case 'aa':
          case 'aaa':
            return match.dayPeriod(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.dayPeriod(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'aaaaa':
            return match.dayPeriod(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'aaaa':
          default:
            return match.dayPeriod(string, {
              width: 'wide',
              context: 'formatting'
            }) || match.dayPeriod(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.dayPeriod(string, {
              width: 'narrow',
              context: 'formatting'
            });
        }
      },
      set: function (date, _flags, value, _options) {
        date.setUTCHours(dayPeriodEnumToHours(value), 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['b', 'B', 'H', 'K', 'k', 't', 'T']
    },
    b: {
      priority: 80,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'b':
          case 'bb':
          case 'bbb':
            return match.dayPeriod(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.dayPeriod(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'bbbbb':
            return match.dayPeriod(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'bbbb':
          default:
            return match.dayPeriod(string, {
              width: 'wide',
              context: 'formatting'
            }) || match.dayPeriod(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.dayPeriod(string, {
              width: 'narrow',
              context: 'formatting'
            });
        }
      },
      set: function (date, _flags, value, _options) {
        date.setUTCHours(dayPeriodEnumToHours(value), 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['a', 'B', 'H', 'K', 'k', 't', 'T']
    },
    B: {
      priority: 80,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'B':
          case 'BB':
          case 'BBB':
            return match.dayPeriod(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.dayPeriod(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'BBBBB':
            return match.dayPeriod(string, {
              width: 'narrow',
              context: 'formatting'
            });

          case 'BBBB':
          default:
            return match.dayPeriod(string, {
              width: 'wide',
              context: 'formatting'
            }) || match.dayPeriod(string, {
              width: 'abbreviated',
              context: 'formatting'
            }) || match.dayPeriod(string, {
              width: 'narrow',
              context: 'formatting'
            });
        }
      },
      set: function (date, _flags, value, _options) {
        date.setUTCHours(dayPeriodEnumToHours(value), 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['a', 'b', 't', 'T']
    },
    h: {
      priority: 70,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'h':
            return parseNumericPattern(numericPatterns.hour12h, string);

          case 'ho':
            return match.ordinalNumber(string, {
              unit: 'hour'
            });

          default:
            return parseNDigits(token.length, string);
        }
      },
      validate: function (_date, value, _options) {
        return value >= 1 && value <= 12;
      },
      set: function (date, _flags, value, _options) {
        var isPM = date.getUTCHours() >= 12;

        if (isPM && value < 12) {
          date.setUTCHours(value + 12, 0, 0, 0);
        } else if (!isPM && value === 12) {
          date.setUTCHours(0, 0, 0, 0);
        } else {
          date.setUTCHours(value, 0, 0, 0);
        }

        return date;
      },
      incompatibleTokens: ['H', 'K', 'k', 't', 'T']
    },
    H: {
      priority: 70,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'H':
            return parseNumericPattern(numericPatterns.hour23h, string);

          case 'Ho':
            return match.ordinalNumber(string, {
              unit: 'hour'
            });

          default:
            return parseNDigits(token.length, string);
        }
      },
      validate: function (_date, value, _options) {
        return value >= 0 && value <= 23;
      },
      set: function (date, _flags, value, _options) {
        date.setUTCHours(value, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['a', 'b', 'h', 'K', 'k', 't', 'T']
    },
    K: {
      priority: 70,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'K':
            return parseNumericPattern(numericPatterns.hour11h, string);

          case 'Ko':
            return match.ordinalNumber(string, {
              unit: 'hour'
            });

          default:
            return parseNDigits(token.length, string);
        }
      },
      validate: function (_date, value, _options) {
        return value >= 0 && value <= 11;
      },
      set: function (date, _flags, value, _options) {
        var isPM = date.getUTCHours() >= 12;

        if (isPM && value < 12) {
          date.setUTCHours(value + 12, 0, 0, 0);
        } else {
          date.setUTCHours(value, 0, 0, 0);
        }

        return date;
      },
      incompatibleTokens: ['a', 'b', 'h', 'H', 'k', 't', 'T']
    },
    k: {
      priority: 70,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'k':
            return parseNumericPattern(numericPatterns.hour24h, string);

          case 'ko':
            return match.ordinalNumber(string, {
              unit: 'hour'
            });

          default:
            return parseNDigits(token.length, string);
        }
      },
      validate: function (_date, value, _options) {
        return value >= 1 && value <= 24;
      },
      set: function (date, _flags, value, _options) {
        var hours = value <= 24 ? value % 24 : value;
        date.setUTCHours(hours, 0, 0, 0);
        return date;
      },
      incompatibleTokens: ['a', 'b', 'h', 'H', 'K', 't', 'T']
    },
    m: {
      priority: 60,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 'm':
            return parseNumericPattern(numericPatterns.minute, string);

          case 'mo':
            return match.ordinalNumber(string, {
              unit: 'minute'
            });

          default:
            return parseNDigits(token.length, string);
        }
      },
      validate: function (_date, value, _options) {
        return value >= 0 && value <= 59;
      },
      set: function (date, _flags, value, _options) {
        date.setUTCMinutes(value, 0, 0);
        return date;
      },
      incompatibleTokens: ['t', 'T']
    },
    s: {
      priority: 50,
      parse: function (string, token, match, _options) {
        switch (token) {
          case 's':
            return parseNumericPattern(numericPatterns.second, string);

          case 'so':
            return match.ordinalNumber(string, {
              unit: 'second'
            });

          default:
            return parseNDigits(token.length, string);
        }
      },
      validate: function (_date, value, _options) {
        return value >= 0 && value <= 59;
      },
      set: function (date, _flags, value, _options) {
        date.setUTCSeconds(value, 0);
        return date;
      },
      incompatibleTokens: ['t', 'T']
    },
    S: {
      priority: 30,
      parse: function (string, token, _match, _options) {
        var valueCallback = function (value) {
          return Math.floor(value * Math.pow(10, -token.length + 3));
        };

        return parseNDigits(token.length, string, valueCallback);
      },
      set: function (date, _flags, value, _options) {
        date.setUTCMilliseconds(value);
        return date;
      },
      incompatibleTokens: ['t', 'T']
    },
    X: {
      priority: 10,
      parse: function (string, token, _match, _options) {
        switch (token) {
          case 'X':
            return parseTimezonePattern(timezonePatterns.basicOptionalMinutes, string);

          case 'XX':
            return parseTimezonePattern(timezonePatterns.basic, string);

          case 'XXXX':
            return parseTimezonePattern(timezonePatterns.basicOptionalSeconds, string);

          case 'XXXXX':
            return parseTimezonePattern(timezonePatterns.extendedOptionalSeconds, string);

          case 'XXX':
          default:
            return parseTimezonePattern(timezonePatterns.extended, string);
        }
      },
      set: function (date, flags, value, _options) {
        if (flags.timestampIsSet) {
          return date;
        }

        return new Date(date.getTime() - value);
      },
      incompatibleTokens: ['t', 'T', 'x']
    },
    x: {
      priority: 10,
      parse: function (string, token, _match, _options) {
        switch (token) {
          case 'x':
            return parseTimezonePattern(timezonePatterns.basicOptionalMinutes, string);

          case 'xx':
            return parseTimezonePattern(timezonePatterns.basic, string);

          case 'xxxx':
            return parseTimezonePattern(timezonePatterns.basicOptionalSeconds, string);

          case 'xxxxx':
            return parseTimezonePattern(timezonePatterns.extendedOptionalSeconds, string);

          case 'xxx':
          default:
            return parseTimezonePattern(timezonePatterns.extended, string);
        }
      },
      set: function (date, flags, value, _options) {
        if (flags.timestampIsSet) {
          return date;
        }

        return new Date(date.getTime() - value);
      },
      incompatibleTokens: ['t', 'T', 'X']
    },
    t: {
      priority: 40,
      parse: function (string, _token, _match, _options) {
        return parseAnyDigitsSigned(string);
      },
      set: function (_date, _flags, value, _options) {
        return [new Date(value * 1000), {
          timestampIsSet: true
        }];
      },
      incompatibleTokens: '*'
    },
    T: {
      priority: 20,
      parse: function (string, _token, _match, _options) {
        return parseAnyDigitsSigned(string);
      },
      set: function (_date, _flags, value, _options) {
        return [new Date(value), {
          timestampIsSet: true
        }];
      },
      incompatibleTokens: '*'
    }
  };

  var TIMEZONE_UNIT_PRIORITY = 10; 

  var formattingTokensRegExp$1 = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g; 

  var longFormattingTokensRegExp = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;
  var escapedStringRegExp$1 = /^'([^]*?)'?$/;
  var doubleQuoteRegExp$1 = /''/g;
  var notWhitespaceRegExp = /\S/;
  var unescapedLatinCharacterRegExp$1 = /[a-zA-Z]/;

  function parse(dirtyDateString, dirtyFormatString, dirtyReferenceDate, dirtyOptions) {
    requiredArgs(3, arguments);
    var dateString = String(dirtyDateString);
    var formatString = String(dirtyFormatString);
    var options = dirtyOptions || {};
    var locale$1 = options.locale || locale;

    if (!locale$1.match) {
      throw new RangeError('locale must contain match property');
    }

    var localeFirstWeekContainsDate = locale$1.options && locale$1.options.firstWeekContainsDate;
    var defaultFirstWeekContainsDate = localeFirstWeekContainsDate == null ? 1 : toInteger(localeFirstWeekContainsDate);
    var firstWeekContainsDate = options.firstWeekContainsDate == null ? defaultFirstWeekContainsDate : toInteger(options.firstWeekContainsDate); 

    if (!(firstWeekContainsDate >= 1 && firstWeekContainsDate <= 7)) {
      throw new RangeError('firstWeekContainsDate must be between 1 and 7 inclusively');
    }

    var localeWeekStartsOn = locale$1.options && locale$1.options.weekStartsOn;
    var defaultWeekStartsOn = localeWeekStartsOn == null ? 0 : toInteger(localeWeekStartsOn);
    var weekStartsOn = options.weekStartsOn == null ? defaultWeekStartsOn : toInteger(options.weekStartsOn); 

    if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
      throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
    }

    if (formatString === '') {
      if (dateString === '') {
        return toDate(dirtyReferenceDate);
      } else {
        return new Date(NaN);
      }
    }

    var subFnOptions = {
      firstWeekContainsDate: firstWeekContainsDate,
      weekStartsOn: weekStartsOn,
      locale: locale$1 

    };
    var setters = [{
      priority: TIMEZONE_UNIT_PRIORITY,
      subPriority: -1,
      set: dateToSystemTimezone,
      index: 0
    }];
    var i;
    var tokens = formatString.match(longFormattingTokensRegExp).map(function (substring) {
      var firstCharacter = substring[0];

      if (firstCharacter === 'p' || firstCharacter === 'P') {
        var longFormatter = longFormatters[firstCharacter];
        return longFormatter(substring, locale$1.formatLong, subFnOptions);
      }

      return substring;
    }).join('').match(formattingTokensRegExp$1);
    var usedTokens = [];

    for (i = 0; i < tokens.length; i++) {
      var token = tokens[i];

      if (!options.useAdditionalWeekYearTokens && isProtectedWeekYearToken(token)) {
        throwProtectedError(token, formatString, dirtyDateString);
      }

      if (!options.useAdditionalDayOfYearTokens && isProtectedDayOfYearToken(token)) {
        throwProtectedError(token, formatString, dirtyDateString);
      }

      var firstCharacter = token[0];
      var parser = parsers[firstCharacter];

      if (parser) {
        var incompatibleTokens = parser.incompatibleTokens;

        if (Array.isArray(incompatibleTokens)) {
          var incompatibleToken = void 0;

          for (var _i = 0; _i < usedTokens.length; _i++) {
            var usedToken = usedTokens[_i].token;

            if (incompatibleTokens.indexOf(usedToken) !== -1 || usedToken === firstCharacter) {
              incompatibleToken = usedTokens[_i];
              break;
            }
          }

          if (incompatibleToken) {
            throw new RangeError("The format string mustn't contain `".concat(incompatibleToken.fullToken, "` and `").concat(token, "` at the same time"));
          }
        } else if (parser.incompatibleTokens === '*' && usedTokens.length) {
          throw new RangeError("The format string mustn't contain `".concat(token, "` and any other token at the same time"));
        }

        usedTokens.push({
          token: firstCharacter,
          fullToken: token
        });
        var parseResult = parser.parse(dateString, token, locale$1.match, subFnOptions);

        if (!parseResult) {
          return new Date(NaN);
        }

        setters.push({
          priority: parser.priority,
          subPriority: parser.subPriority || 0,
          set: parser.set,
          validate: parser.validate,
          value: parseResult.value,
          index: setters.length
        });
        dateString = parseResult.rest;
      } else {
        if (firstCharacter.match(unescapedLatinCharacterRegExp$1)) {
          throw new RangeError('Format string contains an unescaped latin alphabet character `' + firstCharacter + '`');
        } 


        if (token === "''") {
          token = "'";
        } else if (firstCharacter === "'") {
          token = cleanEscapedString$1(token);
        } 


        if (dateString.indexOf(token) === 0) {
          dateString = dateString.slice(token.length);
        } else {
          return new Date(NaN);
        }
      }
    } 


    if (dateString.length > 0 && notWhitespaceRegExp.test(dateString)) {
      return new Date(NaN);
    }

    var uniquePrioritySetters = setters.map(function (setter) {
      return setter.priority;
    }).sort(function (a, b) {
      return b - a;
    }).filter(function (priority, index, array) {
      return array.indexOf(priority) === index;
    }).map(function (priority) {
      return setters.filter(function (setter) {
        return setter.priority === priority;
      }).sort(function (a, b) {
        return b.subPriority - a.subPriority;
      });
    }).map(function (setterArray) {
      return setterArray[0];
    });
    var date = toDate(dirtyReferenceDate);

    if (isNaN(date)) {
      return new Date(NaN);
    } 


    var utcDate = subMilliseconds(date, getTimezoneOffsetInMilliseconds(date));
    var flags = {};

    for (i = 0; i < uniquePrioritySetters.length; i++) {
      var setter = uniquePrioritySetters[i];

      if (setter.validate && !setter.validate(utcDate, setter.value, subFnOptions)) {
        return new Date(NaN);
      }

      var result = setter.set(utcDate, flags, setter.value, subFnOptions); 

      if (result[0]) {
        utcDate = result[0];
        assign(flags, result[1]); 
      } else {
        utcDate = result;
      }
    }

    return utcDate;
  }

  function dateToSystemTimezone(date, flags) {
    if (flags.timestampIsSet) {
      return date;
    }

    var convertedDate = new Date(0);
    convertedDate.setFullYear(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    convertedDate.setHours(date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
    return convertedDate;
  }

  function cleanEscapedString$1(input) {
    return input.match(escapedStringRegExp$1)[1].replace(doubleQuoteRegExp$1, "'");
  }


  function isMatch(dateString, formatString, dirtyOptions) {
    requiredArgs(2, arguments);
    return isValid(parse(dateString, formatString, new Date(), dirtyOptions));
  }


  function isMonday(dirtyDate) {
    requiredArgs(1, arguments);
    return toDate(dirtyDate).getDay() === 1;
  }


  function isPast(dirtyDate) {
    requiredArgs(1, arguments);
    return toDate(dirtyDate).getTime() < Date.now();
  }


  function startOfHour(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    date.setMinutes(0, 0, 0);
    return date;
  }


  function isSameHour(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeftStartOfHour = startOfHour(dirtyDateLeft);
    var dateRightStartOfHour = startOfHour(dirtyDateRight);
    return dateLeftStartOfHour.getTime() === dateRightStartOfHour.getTime();
  }


  function isSameWeek(dirtyDateLeft, dirtyDateRight, dirtyOptions) {
    requiredArgs(2, arguments);
    var dateLeftStartOfWeek = startOfWeek(dirtyDateLeft, dirtyOptions);
    var dateRightStartOfWeek = startOfWeek(dirtyDateRight, dirtyOptions);
    return dateLeftStartOfWeek.getTime() === dateRightStartOfWeek.getTime();
  }


  function isSameISOWeek(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    return isSameWeek(dirtyDateLeft, dirtyDateRight, {
      weekStartsOn: 1
    });
  }


  function isSameISOWeekYear(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeftStartOfYear = startOfISOWeekYear(dirtyDateLeft);
    var dateRightStartOfYear = startOfISOWeekYear(dirtyDateRight);
    return dateLeftStartOfYear.getTime() === dateRightStartOfYear.getTime();
  }


  function startOfMinute(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    date.setSeconds(0, 0);
    return date;
  }


  function isSameMinute(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeftStartOfMinute = startOfMinute(dirtyDateLeft);
    var dateRightStartOfMinute = startOfMinute(dirtyDateRight);
    return dateLeftStartOfMinute.getTime() === dateRightStartOfMinute.getTime();
  }


  function isSameMonth(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    return dateLeft.getFullYear() === dateRight.getFullYear() && dateLeft.getMonth() === dateRight.getMonth();
  }


  function isSameQuarter(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeftStartOfQuarter = startOfQuarter(dirtyDateLeft);
    var dateRightStartOfQuarter = startOfQuarter(dirtyDateRight);
    return dateLeftStartOfQuarter.getTime() === dateRightStartOfQuarter.getTime();
  }


  function startOfSecond(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    date.setMilliseconds(0);
    return date;
  }


  function isSameSecond(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeftStartOfSecond = startOfSecond(dirtyDateLeft);
    var dateRightStartOfSecond = startOfSecond(dirtyDateRight);
    return dateLeftStartOfSecond.getTime() === dateRightStartOfSecond.getTime();
  }


  function isSameYear(dirtyDateLeft, dirtyDateRight) {
    requiredArgs(2, arguments);
    var dateLeft = toDate(dirtyDateLeft);
    var dateRight = toDate(dirtyDateRight);
    return dateLeft.getFullYear() === dateRight.getFullYear();
  }


  function isThisHour(dirtyDate) {
    requiredArgs(1, arguments);
    return isSameHour(Date.now(), dirtyDate);
  }


  function isThisISOWeek(dirtyDate) {
    requiredArgs(1, arguments);
    return isSameISOWeek(dirtyDate, Date.now());
  }


  function isThisMinute(dirtyDate) {
    requiredArgs(1, arguments);
    return isSameMinute(Date.now(), dirtyDate);
  }


  function isThisMonth(dirtyDate) {
    requiredArgs(1, arguments);
    return isSameMonth(Date.now(), dirtyDate);
  }


  function isThisQuarter(dirtyDate) {
    requiredArgs(1, arguments);
    return isSameQuarter(Date.now(), dirtyDate);
  }


  function isThisSecond(dirtyDate) {
    requiredArgs(1, arguments);
    return isSameSecond(Date.now(), dirtyDate);
  }


  function isThisWeek(dirtyDate, options) {
    requiredArgs(1, arguments);
    return isSameWeek(dirtyDate, Date.now(), options);
  }


  function isThisYear(dirtyDate) {
    requiredArgs(1, arguments);
    return isSameYear(dirtyDate, Date.now());
  }


  function isThursday(dirtyDate) {
    requiredArgs(1, arguments);
    return toDate(dirtyDate).getDay() === 4;
  }


  function isToday(dirtyDate) {
    requiredArgs(1, arguments);
    return isSameDay(dirtyDate, Date.now());
  }


  function isTomorrow(dirtyDate) {
    requiredArgs(1, arguments);
    return isSameDay(dirtyDate, addDays(Date.now(), 1));
  }


  function isTuesday(dirtyDate) {
    requiredArgs(1, arguments);
    return toDate(dirtyDate).getDay() === 2;
  }


  function isWednesday(dirtyDate) {
    requiredArgs(1, arguments);
    return toDate(dirtyDate).getDay() === 3;
  }


  function isWithinInterval(dirtyDate, dirtyInterval) {
    requiredArgs(2, arguments);
    var interval = dirtyInterval || {};
    var time = toDate(dirtyDate).getTime();
    var startTime = toDate(interval.start).getTime();
    var endTime = toDate(interval.end).getTime(); 

    if (!(startTime <= endTime)) {
      throw new RangeError('Invalid interval');
    }

    return time >= startTime && time <= endTime;
  }


  function isYesterday(dirtyDate) {
    requiredArgs(1, arguments);
    return isSameDay(dirtyDate, subDays(Date.now(), 1));
  }


  function lastDayOfDecade(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var year = date.getFullYear();
    var decade = 9 + Math.floor(year / 10) * 10;
    date.setFullYear(decade + 1, 0, 0);
    date.setHours(0, 0, 0, 0);
    return date;
  }


  function lastDayOfWeek(dirtyDate, dirtyOptions) {
    requiredArgs(1, arguments);
    var options = dirtyOptions || {};
    var locale = options.locale;
    var localeWeekStartsOn = locale && locale.options && locale.options.weekStartsOn;
    var defaultWeekStartsOn = localeWeekStartsOn == null ? 0 : toInteger(localeWeekStartsOn);
    var weekStartsOn = options.weekStartsOn == null ? defaultWeekStartsOn : toInteger(options.weekStartsOn); 

    if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
      throw new RangeError('weekStartsOn must be between 0 and 6');
    }

    var date = toDate(dirtyDate);
    var day = date.getDay();
    var diff = (day < weekStartsOn ? -7 : 0) + 6 - (day - weekStartsOn);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + diff);
    return date;
  }


  function lastDayOfISOWeek(dirtyDate) {
    requiredArgs(1, arguments);
    return lastDayOfWeek(dirtyDate, {
      weekStartsOn: 1
    });
  }


  function lastDayOfISOWeekYear(dirtyDate) {
    requiredArgs(1, arguments);
    var year = getISOWeekYear(dirtyDate);
    var fourthOfJanuary = new Date(0);
    fourthOfJanuary.setFullYear(year + 1, 0, 4);
    fourthOfJanuary.setHours(0, 0, 0, 0);
    var date = startOfISOWeek(fourthOfJanuary);
    date.setDate(date.getDate() - 1);
    return date;
  }


  function lastDayOfQuarter(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var currentMonth = date.getMonth();
    var month = currentMonth - currentMonth % 3 + 3;
    date.setMonth(month, 0);
    date.setHours(0, 0, 0, 0);
    return date;
  }


  function lastDayOfYear(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var year = date.getFullYear();
    date.setFullYear(year + 1, 0, 0);
    date.setHours(0, 0, 0, 0);
    return date;
  }


  var formattingTokensRegExp = /(\w)\1*|''|'(''|[^'])+('|$)|./g;
  var escapedStringRegExp = /^'([^]*?)'?$/;
  var doubleQuoteRegExp = /''/g;
  var unescapedLatinCharacterRegExp = /[a-zA-Z]/;

  function lightFormat(dirtyDate, dirtyFormatStr) {
    requiredArgs(2, arguments);
    var formatStr = String(dirtyFormatStr);
    var originalDate = toDate(dirtyDate);

    if (!isValid(originalDate)) {
      throw new RangeError('Invalid time value');
    } 


    var timezoneOffset = getTimezoneOffsetInMilliseconds(originalDate);
    var utcDate = subMilliseconds(originalDate, timezoneOffset);
    var result = formatStr.match(formattingTokensRegExp).map(function (substring) {
      if (substring === "''") {
        return "'";
      }

      var firstCharacter = substring[0];

      if (firstCharacter === "'") {
        return cleanEscapedString(substring);
      }

      var formatter = formatters$1[firstCharacter];

      if (formatter) {
        return formatter(utcDate, substring, null, {});
      }

      if (firstCharacter.match(unescapedLatinCharacterRegExp)) {
        throw new RangeError('Format string contains an unescaped latin alphabet character `' + firstCharacter + '`');
      }

      return substring;
    }).join('');
    return result;
  }

  function cleanEscapedString(input) {
    return input.match(escapedStringRegExp)[1].replace(doubleQuoteRegExp, "'");
  }


  function max(dirtyDatesArray) {
    requiredArgs(1, arguments);
    var datesArray; 

    if (dirtyDatesArray && typeof dirtyDatesArray.forEach === 'function') {
      datesArray = dirtyDatesArray; 
    } else if (typeof dirtyDatesArray === 'object' && dirtyDatesArray !== null) {
      datesArray = Array.prototype.slice.call(dirtyDatesArray);
    } else {
      return new Date(NaN);
    }

    var result;
    datesArray.forEach(function (dirtyDate) {
      var currentDate = toDate(dirtyDate);

      if (result === undefined || result < currentDate || isNaN(currentDate)) {
        result = currentDate;
      }
    });
    return result || new Date(NaN);
  }


  function min(dirtyDatesArray) {
    requiredArgs(1, arguments);
    var datesArray; 

    if (dirtyDatesArray && typeof dirtyDatesArray.forEach === 'function') {
      datesArray = dirtyDatesArray; 
    } else if (typeof dirtyDatesArray === 'object' && dirtyDatesArray !== null) {
      datesArray = Array.prototype.slice.call(dirtyDatesArray);
    } else {
      return new Date(NaN);
    }

    var result;
    datesArray.forEach(function (dirtyDate) {
      var currentDate = toDate(dirtyDate);

      if (result === undefined || result > currentDate || isNaN(currentDate)) {
        result = currentDate;
      }
    });
    return result || new Date(NaN);
  }

  var MILLISECONDS_IN_HOUR = 3600000;
  var MILLISECONDS_IN_MINUTE = 60000;
  var DEFAULT_ADDITIONAL_DIGITS = 2;
  var patterns = {
    dateTimeDelimiter: /[T ]/,
    timeZoneDelimiter: /[Z ]/i,
    timezone: /([Z+-].*)$/
  };
  var dateRegex = /^-?(?:(\d{3})|(\d{2})(?:-?(\d{2}))?|W(\d{2})(?:-?(\d{1}))?|)$/;
  var timeRegex = /^(\d{2}(?:[.,]\d*)?)(?::?(\d{2}(?:[.,]\d*)?))?(?::?(\d{2}(?:[.,]\d*)?))?$/;
  var timezoneRegex = /^([+-])(\d{2})(?::?(\d{2}))?$/;

  function parseISO(argument, dirtyOptions) {
    requiredArgs(1, arguments);
    var options = dirtyOptions || {};
    var additionalDigits = options.additionalDigits == null ? DEFAULT_ADDITIONAL_DIGITS : toInteger(options.additionalDigits);

    if (additionalDigits !== 2 && additionalDigits !== 1 && additionalDigits !== 0) {
      throw new RangeError('additionalDigits must be 0, 1 or 2');
    }

    if (!(typeof argument === 'string' || Object.prototype.toString.call(argument) === '[object String]')) {
      return new Date(NaN);
    }

    var dateStrings = splitDateString(argument);
    var date;

    if (dateStrings.date) {
      var parseYearResult = parseYear(dateStrings.date, additionalDigits);
      date = parseDate(parseYearResult.restDateString, parseYearResult.year);
    }

    if (isNaN(date) || !date) {
      return new Date(NaN);
    }

    var timestamp = date.getTime();
    var time = 0;
    var offset;

    if (dateStrings.time) {
      time = parseTime(dateStrings.time);

      if (isNaN(time) || time === null) {
        return new Date(NaN);
      }
    }

    if (dateStrings.timezone) {
      offset = parseTimezone(dateStrings.timezone);

      if (isNaN(offset)) {
        return new Date(NaN);
      }
    } else {
      var dirtyDate = new Date(timestamp + time); 

      var result = new Date(dirtyDate.getUTCFullYear(), dirtyDate.getUTCMonth(), dirtyDate.getUTCDate(), dirtyDate.getUTCHours(), dirtyDate.getUTCMinutes(), dirtyDate.getUTCSeconds(), dirtyDate.getUTCMilliseconds());
      result.setFullYear(dirtyDate.getUTCFullYear());
      return result;
    }

    return new Date(timestamp + time + offset);
  }

  function splitDateString(dateString) {
    var dateStrings = {};
    var array = dateString.split(patterns.dateTimeDelimiter);
    var timeString; 

    if (array.length > 2) {
      return dateStrings;
    }

    if (/:/.test(array[0])) {
      dateStrings.date = null;
      timeString = array[0];
    } else {
      dateStrings.date = array[0];
      timeString = array[1];

      if (patterns.timeZoneDelimiter.test(dateStrings.date)) {
        dateStrings.date = dateString.split(patterns.timeZoneDelimiter)[0];
        timeString = dateString.substr(dateStrings.date.length, dateString.length);
      }
    }

    if (timeString) {
      var token = patterns.timezone.exec(timeString);

      if (token) {
        dateStrings.time = timeString.replace(token[1], '');
        dateStrings.timezone = token[1];
      } else {
        dateStrings.time = timeString;
      }
    }

    return dateStrings;
  }

  function parseYear(dateString, additionalDigits) {
    var regex = new RegExp('^(?:(\\d{4}|[+-]\\d{' + (4 + additionalDigits) + '})|(\\d{2}|[+-]\\d{' + (2 + additionalDigits) + '})$)');
    var captures = dateString.match(regex); 

    if (!captures) return {
      year: null
    };
    var year = captures[1] && parseInt(captures[1]);
    var century = captures[2] && parseInt(captures[2]);
    return {
      year: century == null ? year : century * 100,
      restDateString: dateString.slice((captures[1] || captures[2]).length)
    };
  }

  function parseDate(dateString, year) {
    if (year === null) return null;
    var captures = dateString.match(dateRegex); 

    if (!captures) return null;
    var isWeekDate = !!captures[4];
    var dayOfYear = parseDateUnit(captures[1]);
    var month = parseDateUnit(captures[2]) - 1;
    var day = parseDateUnit(captures[3]);
    var week = parseDateUnit(captures[4]);
    var dayOfWeek = parseDateUnit(captures[5]) - 1;

    if (isWeekDate) {
      if (!validateWeekDate(year, week, dayOfWeek)) {
        return new Date(NaN);
      }

      return dayOfISOWeekYear(year, week, dayOfWeek);
    } else {
      var date = new Date(0);

      if (!validateDate(year, month, day) || !validateDayOfYearDate(year, dayOfYear)) {
        return new Date(NaN);
      }

      date.setUTCFullYear(year, month, Math.max(dayOfYear, day));
      return date;
    }
  }

  function parseDateUnit(value) {
    return value ? parseInt(value) : 1;
  }

  function parseTime(timeString) {
    var captures = timeString.match(timeRegex);
    if (!captures) return null; 

    var hours = parseTimeUnit(captures[1]);
    var minutes = parseTimeUnit(captures[2]);
    var seconds = parseTimeUnit(captures[3]);

    if (!validateTime(hours, minutes, seconds)) {
      return NaN;
    }

    return hours * MILLISECONDS_IN_HOUR + minutes * MILLISECONDS_IN_MINUTE + seconds * 1000;
  }

  function parseTimeUnit(value) {
    return value && parseFloat(value.replace(',', '.')) || 0;
  }

  function parseTimezone(timezoneString) {
    if (timezoneString === 'Z') return 0;
    var captures = timezoneString.match(timezoneRegex);
    if (!captures) return 0;
    var sign = captures[1] === '+' ? -1 : 1;
    var hours = parseInt(captures[2]);
    var minutes = captures[3] && parseInt(captures[3]) || 0;

    if (!validateTimezone(hours, minutes)) {
      return NaN;
    }

    return sign * (hours * MILLISECONDS_IN_HOUR + minutes * MILLISECONDS_IN_MINUTE);
  }

  function dayOfISOWeekYear(isoWeekYear, week, day) {
    var date = new Date(0);
    date.setUTCFullYear(isoWeekYear, 0, 4);
    var fourthOfJanuaryDay = date.getUTCDay() || 7;
    var diff = (week - 1) * 7 + day + 1 - fourthOfJanuaryDay;
    date.setUTCDate(date.getUTCDate() + diff);
    return date;
  } 


  var daysInMonths = [31, null, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  function isLeapYearIndex(year) {
    return year % 400 === 0 || year % 4 === 0 && year % 100;
  }

  function validateDate(year, month, date) {
    return month >= 0 && month <= 11 && date >= 1 && date <= (daysInMonths[month] || (isLeapYearIndex(year) ? 29 : 28));
  }

  function validateDayOfYearDate(year, dayOfYear) {
    return dayOfYear >= 1 && dayOfYear <= (isLeapYearIndex(year) ? 366 : 365);
  }

  function validateWeekDate(_year, week, day) {
    return week >= 1 && week <= 53 && day >= 0 && day <= 6;
  }

  function validateTime(hours, minutes, seconds) {
    if (hours === 24) {
      return minutes === 0 && seconds === 0;
    }

    return seconds >= 0 && seconds < 60 && minutes >= 0 && minutes < 60 && hours >= 0 && hours < 25;
  }

  function validateTimezone(_hours, minutes) {
    return minutes >= 0 && minutes <= 59;
  }


  function parseJSON(argument) {
    requiredArgs(1, arguments);

    if (typeof argument === 'string') {
      var parts = argument.match(/(\d{4})-(\d{2})-(\d{2})[T ](\d{2}):(\d{2}):(\d{2})(?:\.(\d{0,7}))?(?:Z|\+00:?00)?/);

      if (parts) {
        return new Date(Date.UTC(+parts[1], parts[2] - 1, +parts[3], +parts[4], +parts[5], +parts[6], +((parts[7] || '0') + '00').substring(0, 3)));
      }

      return new Date(NaN);
    }

    return toDate(argument);
  }


  function roundToNearestMinutes(dirtyDate, options) {
    if (arguments.length < 1) {
      throw new TypeError('1 argument required, but only none provided present');
    }

    var nearestTo = options && 'nearestTo' in options ? toInteger(options.nearestTo) : 1;

    if (nearestTo < 1 || nearestTo > 30) {
      throw new RangeError('`options.nearestTo` must be between 1 and 30');
    }

    var date = toDate(dirtyDate);
    var seconds = date.getSeconds(); 

    var minutes = date.getMinutes() + seconds / 60;
    var roundedMinutes = Math.floor(minutes / nearestTo) * nearestTo;
    var remainderMinutes = minutes % nearestTo;
    var addedMinutes = Math.round(remainderMinutes / nearestTo) * nearestTo;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), roundedMinutes + addedMinutes);
  }


  function setMonth(dirtyDate, dirtyMonth) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var month = toInteger(dirtyMonth);
    var year = date.getFullYear();
    var day = date.getDate();
    var dateWithDesiredMonth = new Date(0);
    dateWithDesiredMonth.setFullYear(year, month, 15);
    dateWithDesiredMonth.setHours(0, 0, 0, 0);
    var daysInMonth = getDaysInMonth(dateWithDesiredMonth); 

    date.setMonth(month, Math.min(day, daysInMonth));
    return date;
  }


  function set(dirtyDate, values) {
    requiredArgs(2, arguments);

    if (typeof values !== 'object' || values === null) {
      throw new RangeError('values parameter must be an object');
    }

    var date = toDate(dirtyDate); 

    if (isNaN(date)) {
      return new Date(NaN);
    }

    if (values.year != null) {
      date.setFullYear(values.year);
    }

    if (values.month != null) {
      date = setMonth(date, values.month);
    }

    if (values.date != null) {
      date.setDate(toInteger(values.date));
    }

    if (values.hours != null) {
      date.setHours(toInteger(values.hours));
    }

    if (values.minutes != null) {
      date.setMinutes(toInteger(values.minutes));
    }

    if (values.seconds != null) {
      date.setSeconds(toInteger(values.seconds));
    }

    if (values.milliseconds != null) {
      date.setMilliseconds(toInteger(values.milliseconds));
    }

    return date;
  }


  function setDate(dirtyDate, dirtyDayOfMonth) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var dayOfMonth = toInteger(dirtyDayOfMonth);
    date.setDate(dayOfMonth);
    return date;
  }


  function setDay(dirtyDate, dirtyDay, dirtyOptions) {
    requiredArgs(2, arguments);
    var options = dirtyOptions || {};
    var locale = options.locale;
    var localeWeekStartsOn = locale && locale.options && locale.options.weekStartsOn;
    var defaultWeekStartsOn = localeWeekStartsOn == null ? 0 : toInteger(localeWeekStartsOn);
    var weekStartsOn = options.weekStartsOn == null ? defaultWeekStartsOn : toInteger(options.weekStartsOn); 

    if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
      throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
    }

    var date = toDate(dirtyDate, options);
    var day = toInteger(dirtyDay);
    var currentDay = date.getDay();
    var remainder = day % 7;
    var dayIndex = (remainder + 7) % 7;
    var delta = 7 - weekStartsOn;
    var diff = day < 0 || day > 6 ? day - (currentDay + delta) % 7 : (dayIndex + delta) % 7 - (currentDay + delta) % 7;
    return addDays(date, diff, options);
  }


  function setDayOfYear(dirtyDate, dirtyDayOfYear) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var dayOfYear = toInteger(dirtyDayOfYear);
    date.setMonth(0);
    date.setDate(dayOfYear);
    return date;
  }


  function setHours(dirtyDate, dirtyHours) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var hours = toInteger(dirtyHours);
    date.setHours(hours);
    return date;
  }


  function setISODay(dirtyDate, dirtyDay) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var day = toInteger(dirtyDay);
    var currentDay = getISODay(date);
    var diff = day - currentDay;
    return addDays(date, diff);
  }


  function setISOWeek(dirtyDate, dirtyISOWeek) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var isoWeek = toInteger(dirtyISOWeek);
    var diff = getISOWeek(date) - isoWeek;
    date.setDate(date.getDate() - diff * 7);
    return date;
  }


  function setMilliseconds(dirtyDate, dirtyMilliseconds) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var milliseconds = toInteger(dirtyMilliseconds);
    date.setMilliseconds(milliseconds);
    return date;
  }


  function setMinutes(dirtyDate, dirtyMinutes) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var minutes = toInteger(dirtyMinutes);
    date.setMinutes(minutes);
    return date;
  }


  function setQuarter(dirtyDate, dirtyQuarter) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var quarter = toInteger(dirtyQuarter);
    var oldQuarter = Math.floor(date.getMonth() / 3) + 1;
    var diff = quarter - oldQuarter;
    return setMonth(date, date.getMonth() + diff * 3);
  }


  function setSeconds(dirtyDate, dirtySeconds) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var seconds = toInteger(dirtySeconds);
    date.setSeconds(seconds);
    return date;
  }


  function setWeek(dirtyDate, dirtyWeek, dirtyOptions) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var week = toInteger(dirtyWeek);
    var diff = getWeek(date, dirtyOptions) - week;
    date.setDate(date.getDate() - diff * 7);
    return date;
  }


  function setWeekYear(dirtyDate, dirtyWeekYear, dirtyOptions) {
    requiredArgs(2, arguments);
    var options = dirtyOptions || {};
    var locale = options.locale;
    var localeFirstWeekContainsDate = locale && locale.options && locale.options.firstWeekContainsDate;
    var defaultFirstWeekContainsDate = localeFirstWeekContainsDate == null ? 1 : toInteger(localeFirstWeekContainsDate);
    var firstWeekContainsDate = options.firstWeekContainsDate == null ? defaultFirstWeekContainsDate : toInteger(options.firstWeekContainsDate);
    var date = toDate(dirtyDate);
    var weekYear = toInteger(dirtyWeekYear);
    var diff = differenceInCalendarDays(date, startOfWeekYear(date, dirtyOptions));
    var firstWeek = new Date(0);
    firstWeek.setFullYear(weekYear, 0, firstWeekContainsDate);
    firstWeek.setHours(0, 0, 0, 0);
    date = startOfWeekYear(firstWeek, dirtyOptions);
    date.setDate(date.getDate() + diff);
    return date;
  }


  function setYear(dirtyDate, dirtyYear) {
    requiredArgs(2, arguments);
    var date = toDate(dirtyDate);
    var year = toInteger(dirtyYear); 

    if (isNaN(date)) {
      return new Date(NaN);
    }

    date.setFullYear(year);
    return date;
  }


  function startOfDecade(dirtyDate) {
    requiredArgs(1, arguments);
    var date = toDate(dirtyDate);
    var year = date.getFullYear();
    var decade = Math.floor(year / 10) * 10;
    date.setFullYear(decade, 0, 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }


  function startOfToday() {
    return startOfDay(Date.now());
  }

  function startOfTomorrow() {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth();
    var day = now.getDate();
    var date = new Date(0);
    date.setFullYear(year, month, day + 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  function startOfYesterday() {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth();
    var day = now.getDate();
    var date = new Date(0);
    date.setFullYear(year, month, day - 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }


  function subBusinessDays(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addBusinessDays(dirtyDate, -amount);
  }


  function subHours(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addHours(dirtyDate, -amount);
  }


  function subMinutes(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addMinutes(dirtyDate, -amount);
  }


  function subQuarters(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addQuarters(dirtyDate, -amount);
  }


  function subSeconds(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addSeconds(dirtyDate, -amount);
  }


  function subWeeks(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addWeeks(dirtyDate, -amount);
  }


  function subYears(dirtyDate, dirtyAmount) {
    requiredArgs(2, arguments);
    var amount = toInteger(dirtyAmount);
    return addYears(dirtyDate, -amount);
  }

  var maxTime = Math.pow(10, 8) * 24 * 60 * 60 * 1000;

  var minTime = -maxTime;


  exports.add = add;
  exports.addBusinessDays = addBusinessDays;
  exports.addDays = addDays;
  exports.addHours = addHours;
  exports.addISOWeekYears = addISOWeekYears;
  exports.addMilliseconds = addMilliseconds;
  exports.addMinutes = addMinutes;
  exports.addMonths = addMonths;
  exports.addQuarters = addQuarters;
  exports.addSeconds = addSeconds;
  exports.addWeeks = addWeeks;
  exports.addYears = addYears;
  exports.areIntervalsOverlapping = areIntervalsOverlapping;
  exports.closestIndexTo = closestIndexTo;
  exports.closestTo = closestTo;
  exports.compareAsc = compareAsc;
  exports.compareDesc = compareDesc;
  exports.differenceInBusinessDays = differenceInBusinessDays;
  exports.differenceInCalendarDays = differenceInCalendarDays;
  exports.differenceInCalendarISOWeekYears = differenceInCalendarISOWeekYears;
  exports.differenceInCalendarISOWeeks = differenceInCalendarISOWeeks;
  exports.differenceInCalendarMonths = differenceInCalendarMonths;
  exports.differenceInCalendarQuarters = differenceInCalendarQuarters;
  exports.differenceInCalendarWeeks = differenceInCalendarWeeks;
  exports.differenceInCalendarYears = differenceInCalendarYears;
  exports.differenceInDays = differenceInDays;
  exports.differenceInHours = differenceInHours;
  exports.differenceInISOWeekYears = differenceInISOWeekYears;
  exports.differenceInMilliseconds = differenceInMilliseconds;
  exports.differenceInMinutes = differenceInMinutes;
  exports.differenceInMonths = differenceInMonths;
  exports.differenceInQuarters = differenceInQuarters;
  exports.differenceInSeconds = differenceInSeconds;
  exports.differenceInWeeks = differenceInWeeks;
  exports.differenceInYears = differenceInYears;
  exports.eachDayOfInterval = eachDayOfInterval;
  exports.eachHourOfInterval = eachHourOfInterval;
  exports.eachMonthOfInterval = eachMonthOfInterval;
  exports.eachQuarterOfInterval = eachQuarterOfInterval;
  exports.eachWeekOfInterval = eachWeekOfInterval;
  exports.eachWeekendOfInterval = eachWeekendOfInterval;
  exports.eachWeekendOfMonth = eachWeekendOfMonth;
  exports.eachWeekendOfYear = eachWeekendOfYear;
  exports.eachYearOfInterval = eachYearOfInterval;
  exports.endOfDay = endOfDay;
  exports.endOfDecade = endOfDecade;
  exports.endOfHour = endOfHour;
  exports.endOfISOWeek = endOfISOWeek;
  exports.endOfISOWeekYear = endOfISOWeekYear;
  exports.endOfMinute = endOfMinute;
  exports.endOfMonth = endOfMonth;
  exports.endOfQuarter = endOfQuarter;
  exports.endOfSecond = endOfSecond;
  exports.endOfToday = endOfToday;
  exports.endOfTomorrow = endOfTomorrow;
  exports.endOfWeek = endOfWeek;
  exports.endOfYear = endOfYear;
  exports.endOfYesterday = endOfYesterday;
  exports.format = format;
  exports.formatDistance = formatDistance;
  exports.formatDistanceStrict = formatDistanceStrict;
  exports.formatDistanceToNow = formatDistanceToNow;
  exports.formatDistanceToNowStrict = formatDistanceToNowStrict;
  exports.formatDuration = formatDuration;
  exports.formatISO = formatISO;
  exports.formatISO9075 = formatISO9075;
  exports.formatISODuration = formatISODuration;
  exports.formatRFC3339 = formatRFC3339;
  exports.formatRFC7231 = formatRFC7231;
  exports.formatRelative = formatRelative;
  exports.fromUnixTime = fromUnixTime;
  exports.getDate = getDate;
  exports.getDay = getDay;
  exports.getDayOfYear = getDayOfYear;
  exports.getDaysInMonth = getDaysInMonth;
  exports.getDaysInYear = getDaysInYear;
  exports.getDecade = getDecade;
  exports.getHours = getHours;
  exports.getISODay = getISODay;
  exports.getISOWeek = getISOWeek;
  exports.getISOWeekYear = getISOWeekYear;
  exports.getISOWeeksInYear = getISOWeeksInYear;
  exports.getMilliseconds = getMilliseconds;
  exports.getMinutes = getMinutes;
  exports.getMonth = getMonth;
  exports.getOverlappingDaysInIntervals = getOverlappingDaysInIntervals;
  exports.getQuarter = getQuarter;
  exports.getSeconds = getSeconds;
  exports.getTime = getTime;
  exports.getUnixTime = getUnixTime;
  exports.getWeek = getWeek;
  exports.getWeekOfMonth = getWeekOfMonth;
  exports.getWeekYear = getWeekYear;
  exports.getWeeksInMonth = getWeeksInMonth;
  exports.getYear = getYear;
  exports.intervalToDuration = intervalToDuration;
  exports.isAfter = isAfter;
  exports.isBefore = isBefore;
  exports.isDate = isDate;
  exports.isEqual = isEqual;
  exports.isExists = isExists;
  exports.isFirstDayOfMonth = isFirstDayOfMonth;
  exports.isFriday = isFriday;
  exports.isFuture = isFuture;
  exports.isLastDayOfMonth = isLastDayOfMonth;
  exports.isLeapYear = isLeapYear;
  exports.isMatch = isMatch;
  exports.isMonday = isMonday;
  exports.isPast = isPast;
  exports.isSameDay = isSameDay;
  exports.isSameHour = isSameHour;
  exports.isSameISOWeek = isSameISOWeek;
  exports.isSameISOWeekYear = isSameISOWeekYear;
  exports.isSameMinute = isSameMinute;
  exports.isSameMonth = isSameMonth;
  exports.isSameQuarter = isSameQuarter;
  exports.isSameSecond = isSameSecond;
  exports.isSameWeek = isSameWeek;
  exports.isSameYear = isSameYear;
  exports.isSaturday = isSaturday;
  exports.isSunday = isSunday;
  exports.isThisHour = isThisHour;
  exports.isThisISOWeek = isThisISOWeek;
  exports.isThisMinute = isThisMinute;
  exports.isThisMonth = isThisMonth;
  exports.isThisQuarter = isThisQuarter;
  exports.isThisSecond = isThisSecond;
  exports.isThisWeek = isThisWeek;
  exports.isThisYear = isThisYear;
  exports.isThursday = isThursday;
  exports.isToday = isToday;
  exports.isTomorrow = isTomorrow;
  exports.isTuesday = isTuesday;
  exports.isValid = isValid;
  exports.isWednesday = isWednesday;
  exports.isWeekend = isWeekend;
  exports.isWithinInterval = isWithinInterval;
  exports.isYesterday = isYesterday;
  exports.lastDayOfDecade = lastDayOfDecade;
  exports.lastDayOfISOWeek = lastDayOfISOWeek;
  exports.lastDayOfISOWeekYear = lastDayOfISOWeekYear;
  exports.lastDayOfMonth = lastDayOfMonth;
  exports.lastDayOfQuarter = lastDayOfQuarter;
  exports.lastDayOfWeek = lastDayOfWeek;
  exports.lastDayOfYear = lastDayOfYear;
  exports.lightFormat = lightFormat;
  exports.max = max;
  exports.maxTime = maxTime;
  exports.min = min;
  exports.minTime = minTime;
  exports.parse = parse;
  exports.parseISO = parseISO;
  exports.parseJSON = parseJSON;
  exports.roundToNearestMinutes = roundToNearestMinutes;
  exports.set = set;
  exports.setDate = setDate;
  exports.setDay = setDay;
  exports.setDayOfYear = setDayOfYear;
  exports.setHours = setHours;
  exports.setISODay = setISODay;
  exports.setISOWeek = setISOWeek;
  exports.setISOWeekYear = setISOWeekYear;
  exports.setMilliseconds = setMilliseconds;
  exports.setMinutes = setMinutes;
  exports.setMonth = setMonth;
  exports.setQuarter = setQuarter;
  exports.setSeconds = setSeconds;
  exports.setWeek = setWeek;
  exports.setWeekYear = setWeekYear;
  exports.setYear = setYear;
  exports.startOfDay = startOfDay;
  exports.startOfDecade = startOfDecade;
  exports.startOfHour = startOfHour;
  exports.startOfISOWeek = startOfISOWeek;
  exports.startOfISOWeekYear = startOfISOWeekYear;
  exports.startOfMinute = startOfMinute;
  exports.startOfMonth = startOfMonth;
  exports.startOfQuarter = startOfQuarter;
  exports.startOfSecond = startOfSecond;
  exports.startOfToday = startOfToday;
  exports.startOfTomorrow = startOfTomorrow;
  exports.startOfWeek = startOfWeek;
  exports.startOfWeekYear = startOfWeekYear;
  exports.startOfYear = startOfYear;
  exports.startOfYesterday = startOfYesterday;
  exports.sub = sub;
  exports.subBusinessDays = subBusinessDays;
  exports.subDays = subDays;
  exports.subHours = subHours;
  exports.subISOWeekYears = subISOWeekYears;
  exports.subMilliseconds = subMilliseconds;
  exports.subMinutes = subMinutes;
  exports.subMonths = subMonths;
  exports.subQuarters = subQuarters;
  exports.subSeconds = subSeconds;
  exports.subWeeks = subWeeks;
  exports.subYears = subYears;
  exports.toDate = toDate;

  Object.defineProperty(exports, '__esModule', { value: true });

})));


 })(window);